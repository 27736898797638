import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import {CONTENT} from '../../content'

const Footer = (props) => {
  const {lang, pageChanged} = props
  const content = CONTENT[lang]
  const {footer, footerLinks} = content
  const renderers = {
    link: props =>  props.href.match(/^(https?:)?\/\//)
      ? <a href={props.href} target="_blank">{props.children}</a> 
      : <Link onClick={pageChanged} to={`/${lang}${props.href}`}>{props.children}</Link>
  }
  return (
  <footer>
    <div className="row">
      <p className="column offset-1 span-4">
        <ReactMarkdown children={footerLinks} unwrapDisallowed={true} disallowedTypes={['paragraph']} />
      </p>
      <p className="column span-6">&copy;{footer}</p>
    </div>
  </footer>
  )
}

export default Footer