import React, { Component } from 'react';

const Spinner = (props) => {

  const segments = 28
  const text = ` ${props.text}... `
  const arr = [];
  for (let i=0; i<segments; i++) {
    arr.push(<div className="preloader__sector">{text.charAt(i)}</div>) 
  }

  return (
    <div className="preloader">
      <div className="preloader__ring">
        <div className="preloader__sector">&#x1F4E7;</div>
        {arr}
      </div>
    </div>
  )
}


export default Spinner