import React, { Component } from 'react';
import {Helmet} from "react-helmet"
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import stripIndent from '../helpers/stripindent'
import SmartyPants from '../helpers/smartypants'
import {CONTENT} from '../../content'
    
class Services extends Component {
  render() {
    document.documentElement.classList.remove('has-lightbox')
    const {lang, pageChanged, bp} = this.props
    const renderers = {
      link: props =>  props.href.match(/^(https?:)?\/\//)
        ? <a href={props.href} target="_blank">{props.children}</a> 
        : <Link onClick={pageChanged} to={`/${lang}${props.href}`}>{props.children}</Link>
    }
    const content = CONTENT[lang]
    const {titleBar, description, heading, article} = content.services
    return (<>
      <Helmet>
        <title>{ `${titleBar} - ${content.titleBar}` }</title>
        <meta name="description" content={description} />
        <meta name="canonical" content={`https://kingetcie.com/${lang}/services`} />
      </Helmet>
      <article className="services">
      <div className="row">
        <div className={`column ${bp==='mobile' ? 'span-12' :  'offset-1 span-10'}`}>
          <ReactMarkdown children={heading} />
        </div>
      </div>
      <div className="row">
        <section className={`column ${bp==='mobile' ? 'span-12' :  'offset-1 span-10'}`}>
          <ReactMarkdown
            renderers={renderers}
            children={SmartyPants(stripIndent(article))}
          />
        </section>
      </div>
    </article>
    </>)
  }
}
    
export default Services