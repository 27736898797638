import React, { useState } from 'react';
import { Helmet } from 'react-helmet'
import Spinner from './spinner'
import ReactMarkdown from 'react-markdown'
import stripIndent from '../helpers/stripindent'
import SmartyPants from '../helpers/smartypants'
import {CONTENT} from '../../content'

const initialFormData = {
  subject: '',
  firstname: '',
  surname: '',
  email: '',
  message: '',
  language: ''
}

const initialFieldState = {
  subject: false,
  firstname: false,
  surname: false,
  email: false,
  message: false
}

const initialState = {
  showSpinner: false,
  submitted: false,
  hasError: false,
  canSubmit: false,
  showResponse: false,
  error: ''
}

const formUrl = 'https://script.google.com/macros/s/AKfycbzfg0NhHbstoKn3RFD7a4FZfkFyJty0s3x0C-FlyAPvfFNOWUZW_mdYPIVew72ZT695/exec'

const Contact = (props) => {
  const [formData, updateFormData] = useState(initialFormData)
  const [formState, updateFormState] = useState(initialState)
  const [fieldState, updateFieldState] = useState(initialFieldState)

  const formRef = React.createRef();
  const containerRef = React.createRef();

  const touchMe = (e) => {
    // mark field as interacted with
    updateFieldState({
      ...fieldState,
      [e.target.name]: true
    });

  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    });
    const canSubmit = formRef.current.checkValidity();
    updateFormState({
      ...formState,
      canSubmit
    });
  };

  const handleSubmit = (e) => {
    const canSubmit = formRef.current.checkValidity();
    if (!canSubmit) return false;
    e.preventDefault()
   
    const data = {...formData, language: props.lang}
    const queryString = JSON.stringify(data);

    updateFormState({
      ...formState,
      canSubmit:false,
      showSpinner: true,
      showResponse: false,
      hasError: false,
      error:''
    });

    fetch(formUrl, {
      method: 'POST', 
      mode: 'cors',
      cache: 'no-cache',
      redirect: 'follow',
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
      body: queryString
    }).then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json()
    }).then(data => {
      if (data.result && data.result === 'success') {
        updateFormState({
          ...formState,
          showResponse:true,
          showSpinner: false
        })
      }
    }).catch((error) => {
      console.error('Error:', error)
      updateFormState({
        ...formState,
        showSpinner: false,
        hasError: true,
        error
      })
    }).finally( () => {
      updateFieldState({
        ...fieldState
      })
    })
  }

  const renderOptions = (options) => {
   return options.map(option => <option value={option.value} key={option.label}>{option.label}</option>)
  }

  const {lang, pageChanged, bp} = props
  
  const {showResponse, showSpinner, canSubmit, hasError, error} = formState

  const content = CONTENT[lang]
  const {titleBar, description, heading, subheading, article, success, form, spinnerText} = content.contact
  document.documentElement.classList.remove('has-lightbox')
  return (<>
    <Helmet>
      <title>{ `${titleBar} - ${content.titleBar}` }</title>
      <meta name="description" content={description} />
      <meta name="canonical" content={`https://kingetcie.com/${lang}/contact`} />
    </Helmet>
    <article className="contact">
    <div className="row">
      <div className={`column ${bp==='mobile' ? 'span-12' :  'offset-1 span-10'}`}>
        <ReactMarkdown children={heading} />
      </div>
    </div>
    <div className="row">
      <section  className={`column ${bp==='mobile' ? 'span-12' :  'offset-1 span-10'}`}>
        <ReactMarkdown children={subheading} />
        <ReactMarkdown children={SmartyPants(stripIndent(article))} />
      </section>
    </div>
    { !showResponse &&
    <div className="row">
      <form className={`column  ${bp==='desktop' || bp ==='widescreen' ? 'offset-2 span-8' :  bp==='tablet' ? 'offset-1 span-10' : 'span-12'}${showSpinner ? ' blur':''}`} action="#" noValidate ref={formRef} onSubmit={handleSubmit}>
        <div className="row">
          <input type="hidden" name="language" value={lang} />
          { hasError &&
            <h4 className="error">{error}</h4>
          }
          <label className="column span-4" htmlFor="subject">{form.subject.label}</label>
          <select required id="subject" name="subject" className={`column span-7${fieldState.subject ? ' touched':''}`} onChange={handleChange} onBlur={touchMe} defaultValue="">
            {renderOptions(form.subject.options)}
          </select>
          <i></i>
          <label htmlFor="subject" className="show-on-error column offset-4">{form.subject.error}</label>
        </div>
        
        { lang === 'fr' ? <> 
          <div className="row">
            <label className="column span-4" htmlFor="surname">{form.surname.label}</label>
            <input required type="text" id="surname" name="surname" placeholder={form.surname.placeholder} className={`column span-7${fieldState.surname ? ' touched':''}`} onChange={handleChange} onBlur={touchMe}/>
            <i></i>
            <label htmlFor="surname" className="show-on-error column offset-4">{form.surname.required}</label>
          </div>
          <div className="row">
            <label className="column span-4" htmlFor="firstname">{form.firstname.label}</label>
            <input required type="text" id="firstname" name="firstname" placeholder={form.firstname.placeholder} className={`column span-7${fieldState.firstname ? ' touched':''}`} onChange={handleChange} onBlur={touchMe}/>
            <i></i>
            <label htmlFor="firstname" className="show-on-error column offset-4">{form.firstname.error}</label>
          </div>
        </> : <>
          <div className="row">
            <label className="column span-4" htmlFor="firstname">{form.firstname.label}</label>
            <input required type="text" id="firstname" name="firstname" placeholder={form.firstname.placeholder} className={`column span-7${fieldState.firstname ? ' touched':''}`} onChange={handleChange} onBlur={touchMe}/>
            <i></i>
            <label htmlFor="firstname" className="show-on-error column offset-4">{form.firstname.error}</label>
          </div>
          <div className="row">
            <label className="column span-4" htmlFor="surname">{form.surname.label}</label>
            <input required type="text" id="surname" name="surname" placeholder={form.surname.placeholder} className={`column span-7${fieldState.surname ? ' touched':''}`} onChange={handleChange} onBlur={touchMe}/>
            <i></i>
            <label htmlFor="surname" className="show-on-error column offset-4">{form.surname.required}</label>
          </div>
        </>}
        <div className="row">
          <label className="column span-4" htmlFor="email">{form.email.label}</label>
          <input required type="email" id="email" name="email" placeholder={form.email.placeholder} className={`column span-7${fieldState.email ? ' touched':''}`} onChange={handleChange} onBlur={touchMe}/>
          <i></i>
          <label htmlFor="email" className="show-on-error column offset-4">{form.email.error}</label>
        </div>
        <div className="row">
          <label className="column span-4" htmlFor="message">{form.message.label}</label>
          <textarea required id="message" name="message" placeholder={form.message.placeholder} className={`column span-7${fieldState.message ? ' touched':''}`} onChange={handleChange} onBlur={touchMe}></textarea>
          <i></i>
          <label htmlFor="message" className="show-on-error column offset-4">{form.message.error}</label>
        </div>
        <div className="row">
          <button type="submit" disabled={!canSubmit} className="column offset-4 span-4">{form.submit}</button>
        </div> 
      </form>
      {showSpinner &&
        <div className="spinner"><Spinner text={spinnerText} /></div>
      }
    </div> 
    }
    {showResponse &&
    <div className="row">
      <section className={`column ${bp==='desktop' || bp ==='widescreen' ? 'offset-2 span-8' :  bp==='tablet' ? 'offset-1 span-10' : 'span-12'} success`}>
      <ReactMarkdown children={SmartyPants(stripIndent(success))} />
      </section>
    </div>
    }
  </article>
  </>)
}

export default Contact