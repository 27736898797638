import React, { Component } from 'react';
import { Link } from "react-router-dom";

import {CONTENT} from '../../content'

class Nav extends Component {

  nav = () => {
    const {pageChanged, currentPage, lang} = this.props
    const links = []
    Object.entries(CONTENT[lang].menu).forEach(([key, value]) => {
      links.push(<li className={currentPage===`/${lang}${value.path}` ? 'active':''} key={key}><Link to={`/${lang}${value.path}`} onClick={pageChanged}>{value.text}</Link></li>)
    })
    return links;
  }
  
  render() {
    return (
      <nav>
        <ul>
          { this.nav() }
        </ul>
      </nav>
    )
  }
}

export default Nav