import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Nav from './nav';
import LanguageSwitcher from './language-switcher'

import {CONTENT} from '../../content'

class Header extends Component {
  render() {
    const { lang, langChanged, pageChanged, currentPage, bp } = this.props
    const content = CONTENT[lang]
    const isHome = currentPage.length === 4 || currentPage.length === 1
    document.getElementById('kingetcie').className = isHome ? 'home' : ''
    return (
    <header>
      <div className={`row`}>
        <div className={`column ${isHome ? 'span-12' : bp==='mobile' ? 'span-4' :'span-3'} logo`}>
          <h1><Link id="logo" onClick={pageChanged} to={`/${lang}/`}><b>{content.heading}</b></Link></h1>
          <h2>{content.subheading}</h2>
        </div>
        <div className={`column ${isHome || bp==='mobile' ? 'span-12' : bp==='tablet' ? 'span-9' :'pad-1 span-7'} navigation`}>
          <Nav lang={lang} currentPage={currentPage} pageChanged={pageChanged} />
        </div>
        <div className={`column ${bp==='mobile' ? 'span-12' : 'span-3'} switcher`}>
          <LanguageSwitcher lang={lang} currentPage={currentPage} langChanged={langChanged} />
        </div>
      </div>
    </header>
    )
  }
}

export default Header