export default fr = {
  "languageName": "Français",
  "titleBar": "King et Compagnie. Développement de sites web et d'applications web sur mesure",
  "description": "King et Cie est une agence de développement web créée en 2020 par Niall King, un développeur web front-end ayant plus de 20 ans d'expérience en agences commerciales. Basé en Charente, dans le sud-ouest de la France, et travaillant à distance, Niall est disponible pour des services de développement web, d'hébergement et de domaines, sous contrat, en freelance ou à titre personnel.",
  "heading": "King et Compagnie",
  "subheading": "Créateurs de sites web et d'applications sur mesure",
  "footer":"2021 King et Compagnie. SIRET 89188075900016",
  "footerLinks":"[Mentions Légales](/legal) [LinkedIn](https://www.linkedin.com/company/king-et-cie/) [Facebook](https://www.facebook.com/kingetcie)",
  "menu": {
    "home": {
      "text": "Accueil",
      "path": "/"
    },
    "about": {
      "text": "A Propos", 
      "path": "/apropos"
    },
    "services": {
      "text": "Services",
      "path": "/services"
    },
    "work": {
      "text": "Portfolio",
      "path": "/portfolio"
    },
    "contact": {
      "text": "Contact",
      "path": "/contact"
    }
  },
  "home":{
    "heading" : "### Bienvenue à King *&* Compagnie",
    "subheading": "#### Développement web sur mesure basé en Charente, dans le sud-ouest de la France.",
    "article":`**King et Cie** a été créée par Niall King, un développeur web front-end ayant plus de 20 ans d'expérience dans le développement web commercial auprès de certaines des meilleures agences et sociétés de conseil de Londres. Nous pouvons offrir à vos clients le type d'expérience web de haute qualité que vous paieriez une agence à des dizaines ou des centaines de milliers de euros, mais à un prix abordable.

    Pour un aperçu détaillé de ce que nous pouvons faire pour vous, veuillez consulter la [section Services](/services).`,
    "footnote":`Pour voir quelques exemples du travail que nous avons effectué auparavant, veuillez consulter la [section Portfolio](/portfolio).`
  },
  "about":{
    "titleBar":"A propos de King et Compagnie",
    "description":"King et Compagnie a été créée en 2020 par Niall King, un développeur web front-end ayant plus de 20 ans d'expérience en agence commerciale. Basé en Charente, dans le sud-ouest de la France, et travaillant à distance, King et Cie est disponible pour des contrats, du développement web freelance et personnel, de l'hébergement et des services de domaine.",
    "heading" : "### A propos de King *&* Cie",
    "subheading": "#### King *&* Cie a été créée par moi, Niall King, en décembre 2020.",
    "article":`Je suis un développeur web front-end à succès depuis plus de 20 ans. En fait, j'ai créé mon premier site web simple en 1995, quand Netscape 1 était un gamin et qu'Internet Explorer n'était qu'une lueur de désir dans l'œil à Microsoft. En 1999, j'ai commencé à le faire pour gagner ma vie et dès de moment, j'ai eu une carrière très agréable, dans certaines des meilleures agences et sociétés de conseil de Londres, où j'ai créé de fabuleux sites web pour des clients de tous secteurs d'activité. J'ai créé des sites pour des détaillants, des restaurateurs, des clubs, des maisons de disques, des universités, le gouvernement brittanique, des banques, des associations caritatives, des réseaux de téléphonie mobile, des constructeurs automobiles, des événements sportifs majeurs et des clubs de football de Premier League. Vous pouvez decouvrir une sélection de ces sites dans la [section Portfolio](/portfolio).

    Je pense que l'accessibilité et le référencement doivent être conçus dès le début, que le développement front-end est un métier, tout autant que le design, que l'expérience utilisateur est plus importante que l'expérience des développeurs et qu'une bonne conception rend le monde meilleur.

    Vous connaissez sans doute mon travail. Si vous avez suivi Manchester United entre 2008 et 2014, ou l'Open de golf entre 2008 et 2012, vous avez utilisé mon site web. Si vous avez réservé une table via Livebookings ou Bookatable, il se peut que le restaurant ait utilisé mon application de réception. Si vous avez récemment envisagé une nouvelle Mazda, ou si vous avez comparé des forfaits mobiles sur Three au cours des dernières années, il se peut que vous ayez utilisé quelque chose que j'ai construit.

    Mais grâce aux effets de Brexit et Covid-19, j'ai décidé en 2020 qu'il était temps de quitter Londres pour la France rurale et de créer ma propre entreprise. Covid a montré qu'il était possible de travailler entièrement à distance, qu'il n'était pas nécessaire de travailler dans des bureaux ou de rester dans le centre de Londres, et que je pouvais profiter du beau temps tout en bénéficiant de bonnes vitesses de haut débit. C'est ainsi que King et Cie est né. C'est l'abréviation (et la prononciation) de "King et Compagnie" et parce qu'elle est enregistrée en France, je peux enfin utiliser mon propre nom de famille comme nom de société \(qui est interdit en Angleterre à cause de la famille royale\).

    L'avantage du développement en front-end, c'est que je peux faire tout ce que vous voyez et touchez sur un site web. Sans développeur front-end, ce n'est qu'une base de données, mais avec moi, cette base de données deviendra une expérience utilisateur belle et élégante qui peut transformer votre entreprise et votre relation avec vos clients. Je peux maintenant mettre à votre disposition toute mon expérience, mon talent et ma serviabilité générale, et comme je n'ai pas les frais généraux de Londres, je n'ai pas à facturer les prix de Londres.

    **Que puis-je faire pour vous ?** La [section Services](/services) contient une exploration plus complète de ce sujet, mais en substance, si c'est un front-end (c'est-à-dire la partie HTML/CSS/JS) et qu'il est constructible, alors je peux le construire.  Je peux potentiellement le construire s'il n'est pas constructible aussi, mais c'est plus rare !`
  },
  "services":{
    "titleBar":"King et Compagnie Services",
    "description":"King et Compagnie fournit des services de développement web en front-end, et le développement de l'application web React. Niall King est disponible pour des services de développement web contractuel, freelance et personnel, d'hébergement et de domaine",
    "heading" : "### Nos Services",
    "article":`#### Que peut King *&* Cie vous offrir ?

    - Développement de sites web en front-end
    - L'internationalisation : Rendre votre site web multilingue. Avec un version anglais on peut atteindre un marché international
    - Aide à la conception et au design
    - Enregistrement de noms de domaine et services d'hébergement
    - Rédaction, mise à jour de la conception et du contenu pour une meilleure optimisation pour les moteurs de recherche (SEO), accessibilité ou simplement pour ajouter du raffinement et du professionnalisme
    - Migration de votre site d'une ancienne platform vers une nouvelle
    - Mise à jour de votre conception et de votre code pour qu'ils fonctionnent sur les navigateurs, les téléphones et les tablettes modernes

    #### Quels avantages apportons-nous par rapport aux modèles ou aux bibliothèques déjà existants ?

        Lorsque vous choisissez quelque chose «sur étagère» pour votre site web, vous ne collaborez pas avec quelqu'un qui peut examiner vos besoins, vos problèmes et vos possibilités et qui possède les connaissances, l'expertise et l'expérience nécessaires pour y répondre. Toutes les bibliothèques préfabriquées \(par exemple, Bootstrap, Foundation\) essaient de résoudre les problèmes de tout le monde à la fois, et par conséquent ne résolvent jamais complètement ceux de quiconque. La moitié du travail consiste à outrepasser leurs comportements par défaut, et le résultat est *beaucoup* trop de code, et un site web lent qui ressemble à tous les autres. Dans une moindre mesure, il en va de même pour les modèles «sur étagère» pour les systèmes de gestion de contenu comme Wordpress. Vous avez beaucoup plus de contrôle sur ce qui entre dans chaque page, mais en fin de compte, elle n'a pas été construite pour vous - c'est juste une approximation proche. De plus, le code sous-jacent de ces sites web est obsolète. Ils contiennent tous du code qui leur permet de fonctionner avec des navigateurs obsolètes \(par exemple Internet Explorer, Opera ou Edge\) que personne n'utilise plus, et qui fonctionnent moins bien sur les navigateurs modernes.

        Si vous m'engagez pour vous construire quelque chose sur mesure, en revanche, j'apporte une vaste expérience en matière de conseil et d'agence pour comprendre les problèmes que vous devez résoudre et l'expérience que vous voulez apporter. Je coderai exactement ce dont vous avez besoin pour cela, de manière efficace et sans gras inutile. Je vous livrerai un site web rapide, élégant, facile à entretenir, accessible et optimisé pour les recherches, qui fonctionne avec différents navigateurs, dans différentes langues \(si nécessaire\) et qui utilise les dernières techniques pour fonctionner avec tous les navigateurs à valeur ajoutée (en suprimant les codes inutiles pour les rendre obsolètes). Votre site web sera le meilleur possible. Imaginez qu'un site web soit un costume ; si un site Bootstrap est Celio, l'un des miens est Cifonelli.

        #### Quelle est l'expérience de King *&* Cie ?

        En bref, plus de 20 ans de développement de sites web front-end, mais cela couvre un large domaine de nos jours. Je crée des sites web et des applications web, et je les rends rapides, élégants et efficaces. J'ai une préfènce pour ce qu'on appelle le développement UX à l'ingénierie JavaScript pure, mais j'ai aussi une bonne dose d'expérience dans ce domaine. Je code en HTML et CSS ainsi qu'en JavaScript et je considère ces deux éléments comme la base d'un bon site web. J'ai une vision globale de ce que je construis et de la façon dont il s'intègre dans l'écosystème qu'il occupe, le marché dans lequel il s'inscrit ou la stratégie globale de l'entreprise. Je comprends comment les besoins de l'entreprise se peuvent être transformés en un produit et soutenus par celui-ci. Je considère le codage comme un processus créatif et je travaille bien en equipe avec les concepteurs, les clients, les chefs de projet et les autres développeurs pour définir et fournir une expérience utilisateur exceptionnelle. J'ai également gagné l'expérience de la gestion d'équipes de développeurs, si cela s'avère nécessaire. Et ma première expérience dans le domaine des événements sportifs signifie que je tiens mes promesses, à temps. Après tout, vous ne pouvez pas reporter l'Open parce que le site web n'est pas encore terminé !

        #### Qu'entendez-vous par "front-end" ?

        Traditionnellement, les sites web sont divisés en deux parties, l'une est l'arrière-plan, c'est-à-dire l'extraction de données ou le traitement des formulaires, et l'autre est l'ensemble des éléments d'un site web que vous pouvez voyer et toucher. Pendant des années, c'est ainsi que les sites web ont été conçus, et le front-end exigeait la maîtrise du HTML et du CSS, le JavaScript permettant des interactions élégantes avec des scripts simples lorsque nécessaire. Aujourd'hui, JavaScript est devenu un langage de programmation sérieux, qui peut être utilisé aussi bien en amont qu'en aval, et qui sert à créer des applications qui peuvent exister sur le web, ou nativement sur votre ordinateur ou votre appareil. La frontière entre les sites web et les applications web s'est estompée, tout comme la frontière entre le back-end et le front-end. Par exemple, ce site web est s'agit vraiment d'une application web construite avec React, de sorte que je pourrais plus facilement le rendre bilingue.

        #### Quelles technologies utilisons-nous ?

        Les bases sont HTML, CSS et JavaScript selon les derniers standards. J'utilise React, Redux, SASS/SCSS et JSON régulièrement, et j'ai également de l'expérience avec GraphQL, LESS, Angular (mais s'il vous plaît, ne me le demandez plus !), les scripts de construction Webpack, Gulp et Parcel, les tests FE avec Jasmine et Jest, la construction pour Electron, la construction d'applications JavaScript côté serveur avec NodeJS, et une expérience assez ancienne de la maintenance de code côté serveur écrit en Java & JSP, .Net (C## dans les variétés Webforms ou MVC / Razor)

        #### Quelles sont les méthodes de gestion de projet que nous connaissons ?

        J'ai une grande expérience de travail dans toutes sortes d'équipes Agile et Scrum, du Lean et du Kanban à l'Agile «cargo-cult» ainsi que dans un environnement Waterfall, et j'en suis venu à comprendre deux choses. Premièrement, peu importe la façon dont vous gérez, ce qui compte, c'est que vous le gériez. Ensuite, la bonne quantité de gestion est «aussi peu que possible». C'est un petit cadeau pour vous !

        #### Concevons-nous aussi bien que construisons-nous ?

        Je ne m'appellerais pas «designer» et je préfère travailler avec un designer professionnel ou avec des designs existants, mais j'ai appris beaucoup par osmose au cours de ma carrière et je fais parfois du design en plus du développement. L'un de mes points forts a propos des processus de création est de prendre quelque chose qui existe et de lui donner ce «vernis» qui lui donne un aspect professionnel et élégant. Si c'est le genre de travail dont vous avez besoin, [veuillez me contacter](/contact).

        #### Combien cela va-t-il coûter ?

        Cela dépend beaucoup de vos besoins. Pour l'instant, tout travail que je fais est entièrement sur mesure et sera facturé sur la base du temps et des matériaux - soit 75&euro; de l'heure ou 500&euro; par jour, mais j'espère bientôt mettre au point des offres à prix fixe qui vous donneront un site web simple et modélisé pouvant être livré rapidement et exigeant peu de maintenance, mais qui donnera à votre entreprise le même aspect soigné, professionnel et personnalisé qu'un site construit selon vos besoins exacts.

        La TVA est facturée à 20% pour les clients dans l'UE.

        ##### Veuillez [me contacter](/contact) pour discuter plus en détail de vos besoins.
    `
  },
  "work":{
    "titleBar":"Portfolio des travaux antérieurs",
    "description":"Avec deux décennies de travail à leur actif, il n'est pas surprenant que King et Compagnie dispose d'un important portefeuille de travaux antérieurs. Voici quelques uns des projets sur lesquels Niall a travaillé.",
    "heading" : "### Portfolio",
    "subheading" : "#### King et Compagnie s'appuie sur plus de deux décennies de développement commercial réussi du web.",
    "article":`J'ai construit beaucoup de sites au fil des ans. Certains existent toujours, d'autres ont succombé au passage du temps. Voici une sélection représentative de mes travaux antérieurs. Cela devrait vous donner une idée de la qualité du travail que je produis, et peut-être de ce que je peux faire pour vous.
    `,
    "footnote": "#### Si vous aimez ce que vous voyez, veuillez me contacter pour discuter plus en détail de vos besoins.",
    "list" : [
      {
        "date":"2022-2023",
        "client":"Japan Centre",
        "description":`Après avoir impressionné Japan Centre avec ma livraison pour Yokocho, on m'a demandé d'aider à définir une nouvelle architecture technique pour la mettre en place pour l'avenir, et de développer un nouveau site Web Japan Centre à ce sujet. Travaillant dès le départ avec un designer, un expert UX, des experts SEO, des parties prenantes internes et une agence de développement back-end externe, nous avons spécifié le superbe système de gestion de contenu sans tête Amplience, Algolia comme moteur de recherche, tandis que BigCommerce a été choisi comme fournisseur de panier d'achat. Pour le front-end, j'ai construit une application React, en choisissant des intégrations petites et rapides pour la vitesse et la fiabilité. Pendant plusieurs mois, nous avons défini, conçu et construit le site, formé les utilisateurs à sa maintenance et apporté des améliorations techniques et un soutien continus. Combiner produits, éditoriaux, offres, événements et recettes en une expérience holistique
        
        Japancentre.com était la première fois en près de 15 ans où j'ai été si profondément impliqué dans chaque étape de sa production, ainsi que d'être mon premier site de commerce électronique jamais. Il a remporté la médaille d'argent aux 2023 eCommerce Awards du meilleur site de commerce électronique de l'année au Royaume-Uni, et a été décrit comme "l'une des constructions de technologie MACH Alliance les plus rapides". Le nouveau site a entraîné une augmentation de 4,2 % des revenus, une augmentation de 10,7 % des conversions et une augmentation de 7,5 % de la valeur des commandes.
        
        [Ramen Yokocho](https://japancentre.com/)`,
        "images" : [
          "jc1.jpg",
          "jc2.jpg",
          "jc4.jpg",
          "jc9.jpg",
          "jc8.jpg",
          "jc7.jpg"
        ]
      },
      {
        "date":"2021",
        "client":"Ramen Yokocho",
        "description":`J'ai été engagé pour développer un nouveau site web pour le fantastique restaurant rétro japonais de ramen du Japan Centre, Yokocho. Une agence précédente avait essayé mais n'a pas réussi à livrer le design, alors j'ai été appelé pour sauver le projet, ce que j'ai fait. Le site a été construit sur un ancien back-end Ruby on Rails, ce qui a été une grande courbe d'apprentissage pour moi, et a plutôt rendu les choses difficiles techniquement. C'était un bon exemple de la raison pour laquelle l'embauche de développeurs "full-stack" n'est souvent pas la bonne approche, et un spécialiste front-end est nécessaire.
        
        [Ramen Yokocho](https://ramenyokocho.com/)`,
        "images" : [
          "yokocho1.jpg",
          "yokocho2.jpg",
          "yokocho3.jpg",
          "yokocho4.jpg",
          "yokocho5.jpg",
          "yokocho6.jpg"
        ]
      },
      {
        "date":"2019",
        "client":"Paypal",
        "description":`J'ai travaillé pour Paypal à San Jose et au Royaume-Uni pour réviser l'architecture obsolète qui sous-tendait l'expérience de déconnexion de Paypal.com et la faire migrer de DustJS à React. Bien qu'il s'agisse d'un projet de grande envergure, comprenant toutes les pages que vous voyez lorsque vous n'êtes pas connecté à votre compte Paypal, il s'agissait essentiellement d'un simple travail de relance, souvent en utilisant des bibliothèques de modèles existantes. Néanmoins, le maintien de la qualité et des performances était fondamental.
        
        [Paypal](https://www.paypal.com/)`,
        "images" : [
          "paypal1.jpg",
          "paypal2.jpg",
          "paypal4.jpg"
        ]
      },
      {
        "date":"2019",
        "client":"American Express",
        "description":`Il est souvent très difficile pour les entreprises d'évaluer l'efficacité de leurs campagnes de marketing numérique. Pour faciliter la tâche, j'ai réalisé cette application web React pour American Express. Elle présente une visualisation de leurs différentes campagnes de référencement et des performances générales des moteurs de recherche de manière simple, leur permettant de voir rapidement quelles sont les campagnes qui fonctionnent et celles qui fonctionnent moins biens. Elle utilise un back-end Node/Express/MongoDB.`,
        "images" : [
          "amex1.jpg",
          "amex2.jpg",
          "amex3.jpg",
          "amex4.jpg"
        ]
      },
      {
        "date":"2018-2019",
        "client":"Mazda",
        "description":`Il s'agissait du nouveau site web et de la nouvelle plateforme de Mazda Europe, qui ont été déployés sur tous leurs marchés en Europe en 2018 et 2019. Il a été construit en utilisant un frontal React avec le CMS EpiServer en arrière-plan. C'était un projet collaboratif énorme, et je faisais partie d'une équipe de développement beaucoup plus grande pour celui-ci, donc à part la contribution de beaucoup de corrections de bugs et le travail sur le polissage du style, je ne peux revendiquer qu'un plus grand crédit pour certaines parties, comme le localisateur de concessionnaires, ou le microsite de lancement de la nouvelle Mazda 3.
        
        [Mazda Europe](https://www.mazda.de/)`,
        "images" : [
          "mazda1.jpg",
          "mazda4.jpg"
        ]
      },
      {
        "date":"2019",
        "client":"Avis Inspires: Portugal",
        "description":`J'ai construit ce microsite pour Avis, société de location de voitures au Royaume-Uni, dans le cadre de leur plateforme de blog, qui était basée sur Wordpress. J'ai utilisé un plugin GraphQL pour récupérer les données sans tête afin d'avoir un contrôle complet du frontal, que j'ai construit à l'aide de React.`,
        "images" : [
          "avis1.jpg",
          "avis2.jpg",
          "avis3.jpg"
        ]
      },
      {
        "date":"2019",
        "client":"Salomon Orchestra",
        "description":`C'était mon premier projet de design depuis quelques années. Le Salomon Orchestra est un orchestre symphonique amateur basé à Londres. L'objectif était de créer une identité de marque moderne, reconnaissable et distincte, afin d'augmenter la vente des billets pour les des concerts, les réservations et la notoriété de l'orchestre. Il me fallait trouver un vocabulaire visuel qui évoque la musique, mais qui évite tous les clichés des autres conceptions de musique classique. Inspirée par le style visuel de Blue Note Records (par exemple, les polices de caractères, la photographie monochrome teintée et les éléments graphiques forts), j'ai créé les nouveaux logos, ainsi que ces conceptions de dépliants et de programmes. La fréquentation des concerts a considérablement augmenté en 2019.`,
        "images" : [
          "salomon.jpg"
        ]
      },
      {
        "date":"2019",
        "client":"Dignity Funerals",
        "description":`Un outil que j'ai créé pour Dignity Funerals afin de guider les gens à travers les étapes à suivre lorsqu'un en cas de décès d'un proche, de la manière la plus simple et la plus claire possible dans un moment aussi difficile.
        
        En raison des exigences du client en matière d'hébergement et d'infrastructure, cet outil a été entièrement construit à la main en JavaScript "vanille", avec des modèles de Handlebars, plutôt que quelque chose de dynamique comme une application React, mais il utilise une architecture moderne de micro-services pour fournir des informations personnelles pertinentes.
        
        [What to do when someone dies](https://www.dignityfunerals.co.uk/first-steps/)`,
        "images" : [
          "dignity1.jpg",
          "dignity3.jpg"
        ]
      },
      {
        "date":"2016",
        "client":"Lloyds Register of Shipping",
        "description":`J'ai fait partie de l'équipe de développement de l'outil MAST (Marine Asset Survey Tool) pour Lloyds Register. Il s'agissait d'une application électronique construite avec Angular. Elle a remplacé un grand nombre de processus et de formulaires disparates par une application que les experts pouvaient utiliser sur le terrain pour inspecter et certifier les navires, les plateformes pétrolières et autres engins de mer. C'est comme une CT pour les bateux.`,
        "images" : [
          "lloyds.jpg"
        ]
      },
      {
        "date":"2013-2016",
        "client":"Three mobile UK",
        "description":`«Three» sont l'un des grands réseaux de téléphonie mobile au Royaume-Uni. J'étais responsable de l'équipe de développement du site web des Three UK et j'ai développé une grande partie de leur site web réactif de 2013 à 2016, en commençant par la page d'accueil, puis la liste des produits et les pages de détails, et le vérificateur de couverture. Grâce en partie à notre travail d'optimisation, Three a été le seul site web du réseau mobile britannique à continuer à prendre des commandes tous les jours de lancement de l'iPhone, alors que ses sites concurrents ont croulé sous la demande.`,
        "images" : [
          "three1.jpg",
          "three2.jpg",
          "three3.jpg",
          "three5.jpg"
        ]
      },
      {
        "date":"2011-2012",
        "client":"Livebookings (Bookatable)",
        "description":`J'ai travaillé pour Livebookings en tant que responsable du front end d'une application web Front-of-House pour la gestion des restaurants, permettant aux restaurateurs de concevoir des plans de table, de gérer les réservations et de placer les clients en temps réel, sur des tablettes ou des terminaux de point de vente. L'application a été conçue à l'aide d'un front-end JavaScript entièrement personnalisé et d'un back-end C# .Net.`,
        "images" : [
          "lb8.jpg",
          "lb4a.jpg",
          "lb5.jpg",
          "lb6.jpg",
        ]
      },
      {
        "date":"2007-2010",
        "client":"Manchester United",
        "description":`Manchester United est certainement le plus grand club de football au monde, ce qui signifie qu'il devait proposer son site web en plusieurs langues. En 2007, j'ai dirigé le développement du front-end pour l'introduction des versions japonaise, chinoise et coréenne de ManUtd.com, et en 2010, j'ai entrepris une réécriture complète de la base de code pour la rendre sémantique, accessible et conforme aux normes, afin d'ajouter les versions arabe, française et espagnole du site.`,
        "images" : [
          "manutd1.jpg",
          "manutd3.jpg",
          "manutd4.jpg",
          "manutd6.jpg"
        ]
      },
      {
        "date":"2012",
        "client":"Cadbury",
        "description":`Spots v Stripes était une campagne cross-médiatique du chocolatier Cadbury pour promouvoir son parrainage des Jeux Olympiques de Londres 2012. J'ai développé le front-end de plusieurs éléments majeurs du site web de la campagne Spots v Stripes de Cadbury, notamment leurs campagnes Catch and Race Season. J'ai bien aimé celle-ci, car ils nous ont offert d'énormes boîtes de chocolat !`,
        "images" : [
          "cadbury1.jpg",
          "cadbury2.jpg",
          "cadbury4.jpg"
        ]
      },
      {
        "date":"2008-2011",
        "client":"The Open Championship",
        "description":`En 2008, le site de l'Open Championship a été remanié et j'ai rédigé l'intégralité du front-end. J'ai introduit des fonctionnalités alors révolutionnaires sur un site web aussi fréquenté, comme un carrousel de joueurs (à la manière du Cover Flow d'Apple) et des guides de parcours interactifs. De 2008 à 2011, j'ai dirigé les développements ultérieurs, notamment une nouvelle section sur l'histoire, des archives vidéo et une toute nouvelle architecture de notation en direct, l'une des plus avancées à ce jour.

        Ce site a remporté le prix dans le concours BIMA pour la production de sites web 2008. Cette année-là, il a été le seul site web non-Flash de toute la gamme à remporter un prix .`,
        "images" : [
          "og1.jpg",
          "og2.jpg",
          "og4.jpg",
          "og6.jpg"
        ]
      },
      {
        "date":"2004",
        "client":"London Business School",
        "description":`J'ai dirigé le développement du site web de la London Business School en 2004, et j'avais carte blanche pour écrire le code comme je le souhaitais. J'ai donc créé un site en CSS, entièrement conformant aux normes, accessible et adaptable, avec un excellent référencement intégré dès le départ. Tout cela est devenu une pratique courante aujourd'hui, mais à l'époque, c'était une approche assez nouvelle, peut-être révolutionnaire. C'était le plus gros site que j'avais fait à ce moment-là, et cela a vraiment mis ma carrière sur la voie.`,
        "images" : [
          "lbs1.jpg",
          "lbs2.jpg",
          "lbs5.jpg"
        ]
      },
      {
        "date":"2003",
        "client":"Stranger Than Paradise",
        "description":`Stranger Than Paradise était un club mensuel avec un zeste de burlesque et une forte ambiance romantique, gitane, circassienne et carnavalesque. J'avais été recommandé à la suite d'un site que j'avais réalisé pour le Club Montepulciano, et c'était un plaisir de travailler avec les clients, qui m'ont donné beaucoup d'informations sur l'esthétique qu'ils souhaitaient pour le club. J'ai même discuté avec eux de leur préférences de musique et films et, bien sûr, je me suis rendu plusieurs fois au club pour m'en faire une impression. Cette forte contribution du client m'a permis de me faire une idée précise de ce que devait être le design. J'ai conçu et réalisé le site web pour eux, ainsi que leurs logos et d'autres éléments de leur identité de marque, et ils l'ont adoré.`,
        "images" : [
          "stp1.jpg",
          "stp3.jpg",
          "stp4.jpg"
        ]
      },
      {
        "date":"2002",
        "client":"Smallframes.com",
        "description":`Smallframes.com est un site que j'ai créé en 2002, alors que je venais d'acheter une Vespa ET3 d'époque et que je ne trouvais aucune ressource sur internet qui decrivé son entretien et sa réparation. J'ai donc entrepris d'en créer une telle ressource pour la communauté. Essentiellement, j'ai rassemblé toutes les informations existantes que je pouvais, ainsi que des guides photos créés à chaque fois que j'ai bricolé sur mon scooter (ce qui était souvent le cas). Étonnamment, il est devenu très populaire et sert de référence à ce jour. Comme ma Vespa, il est très vieux et demande beaucoup de travail, mais il est encore en vie.

        [Smallframes.com](http://smallframes.com/)`,
        "images" : [
          "sf1.jpg",
          "sf3.jpg",
          "sf4.jpg"
        ]
      }
    ]
  },
  "contact":{
    "titleBar":"Contactez King et Compagnie",
    "description":"Si vous souhaitez engager King et Cie pour effectuer un travail pour vous, voici comment nous contacter.",
    "heading" : "### Contacter King *&* Compagnie",
    "subheading" : "#### Nous aimerions beaucoup parler.",
    "article" : `Si vous pensez que King et Cie peut convenir à votre projet, veuillez remplir ce formulaire et nous vous répondrons dès que possible.`,
    "success" : `#### Merci de nous avoir contactés.

                  Nous répondrons à votre demande dès que possible.`,
    "spinnerText" : "Envoi",
    "form" : {
      "subject" : {
        "label" : "Comment pouvons-nous vous aider ?",
        "error" : "Veuillez sélectionner un sujet",
        "options" : [
          {
            "label" : "Veuillez sélectionner...",
            "value" : ""
          },
          {
            "label" : "Je voudrais discuter d'un rôle de contrat",
            "value" : "1 contract"
          },
          {
            "label" : "Je voudrais parler d'un projet en freelance",
            "value" : "2 freelance"
          },
          {
            "label" : "Je souhaite une aide pour les domaines et l'hébergement",
            "value" : "3 domains"
          },
          {
            "label" : "Je souhaite rendre mon site web bilingue",
            "value" : "bilingual"
          },
          {
            "label" : "Autre (veuillez préciser ci-dessous)",
            "value" : "5 other"
          }
        ]
      },
      "firstname" : {
        "label" : "Prénom",
        "error" : "Veuillez entrer votre prénom",
        "placeholder" : "Jean" 
      },
      "surname" : {
        "label" : "Nom",
        "error" : "Veuillez entrer votre nom de famille",
        "placeholder" : "Monnet" 
      },
      "email" : {
        "label" : "Adresse email",
        "error" : "Veuillez entrer une adresse email valide",
        "placeholder" : "jean.monnet@example.com" 
      },
      "message" : {
        "label" : "Message",
        "error" : "Veuillez saisir votre message",
        "placeholder" : "Je souhaite me renseigner sur l'embauche..." 
      },
      "submit" : "Envoyer un message"
    }  
  },
  "notfound": {
    "titleBar":"Page non trouvée",
    "description":"King et Cie est une agence de développement web créée en 2020 par Niall King, un développeur web front-end ayant plus de 20 ans d'expérience en agences commerciales. Basé en Charente, dans le sud-ouest de la France, et travaillant à distance, Niall est disponible pour des services de développement web, d'hébergement et de domaines, sous contrat, en freelance ou à titre personnel.",
    "heading" : "### Page non trouvée - 404",
    "article" : `## Désolé, ce n'est pas ce que vous cherchiez. A moins que ce que vous cherchiez c'est cette belle Peugeot 404 cabriolet, dans ce cas, vous êtes là !`
  },
  "legal": {
    "titleBar":"Mentions Légales",
    "description":"King et Cie est une agence de développement web créée en 2020 par Niall King, un développeur web front-end ayant plus de 20 ans d'expérience en agences commerciales. Basé en Charente, dans le sud-ouest de la France, et travaillant à distance, Niall est disponible pour des services de développement web, d'hébergement et de domaines, sous contrat, en freelance ou à titre personnel.",
    "heading" : "### Mentions Légales",
    "article" : `#### 1. Présentation du site.
    En vertu de l'article 6 de la loi № 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site kingetcie.com / kingetcie.fr l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :
    
    Propriétaire : King et Cie – 4 Grand' Rue - 16500 Confolens - France
    
    Créateur : Niall KING
    
    Responsable publication : Niall KING
    
    Webmaster : Niall KING – king@kingetcie.com
    
    Hébergeur : 1&1 IONOS Ltd - Discovery House - 154 Southgate Street - Gloucester GL1 2EX - United Kingdom
    
    #### 2. Conditions générales d'utilisation du site et des services proposés.
    L'utilisation du site kingetcie.com / kingetcie.fr implique l'acceptation pleine et entière des conditions générales d'utilisation ci-après décrites. Ces conditions d'utilisation sont susceptibles d'être modifiées ou complétées à tout moment, les utilisateurs du site kingetcie.com / kingetcie.fr sont donc invités à les consulter de manière régulière.
    
    Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par kingetcie.com / kingetcie.fr, qui s'efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l'intervention.
    
    Le site kingetcie.com / kingetcie.fr est mis à jour régulièrement par King et Cie. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s'imposent néanmoins à l'utilisateur qui est invité à s'y référer le plus souvent possible afin d'en prendre connaissance.
    
    #### 3. Description des services fournis.
    Le site kingetcie.com / kingetcie.fr a pour objet de fournir une information concernant l'ensemble des activités de la société.
    
    King et Cie s'efforce de fournir sur le site kingetcie.com / kingetcie.fr des informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu'elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.
    
    Tous les informations indiquées sur le site kingetcie.com / kingetcie.fr sont données à titre indicatif, et sont susceptibles d'évoluer. Par ailleurs, les renseignements figurant sur le site kingetcie.com / kingetcie.fr ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.
    
    #### 4. Limitations contractuelles sur les données techniques.
    Le site utilise la technologie JavaScript.
    
    Le site Internet ne pourra être tenu responsable de dommages matériels liés à l'utilisation du site. De plus, l'utilisateur du site s'engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.
    
    #### 5. Propriété intellectuelle et contrefaçons.
    King et Cie est propriétaire des droits de propriété intellectuelle ou détient les droits d'usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.
    
    Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : King et Cie.
    
    Toute exploitation non autorisée du site ou de l'un quelconque des éléments qu'il contient sera considérée comme constitutive d'une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
    
    #### 6. Limitations de responsabilité.
    King et Cie ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l'utilisateur, lors de l'accès au site kingetcie.com / kingetcie.fr, et résultant soit de l'utilisation d'un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l'apparition d'un bug ou d'une incompatibilité
    
    King et Cie ne pourra également être tenu responsable des dommages indirects (tels par exemple qu'une perte de marché ou perte d'une chance) consécutifs à l'utilisation du site kingetcie.com / kingetcie.fr.
    
    Des espaces interactifs (possibilité de poser des questions dans l'espace contact) sont à la disposition des utilisateurs. King et Cie se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données. Le cas échéant, King et Cie se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l'utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…).
    
    #### 7. Gestion des données personnelles.
    En France, les données personnelles sont notamment protégées par la loi № 78-87 du 6 janvier 1978, la loi № 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.
    
    À l'occasion de l'utilisation du site kingetcie.com / kingetcie.fr, peuvent êtres recueillies : l'URL des liens par l'intermédiaire desquels l'utilisateur a accédé au site kingetcie.com / kingetcie.fr, le fournisseur d'accès de l'utilisateur, l'adresse de protocole Internet (IP) de l'utilisateur.
    
    En tout état de cause King et Cie ne collecte des informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le site kingetcie.com / kingetcie.fr. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du site kingetcie.com / kingetcie.fr l'obligation ou non de fournir ces informations.
    
    Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, tout utilisateur dispose d'un droit d'accès, de rectification et d'opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d'une copie du titre d'identité avec signature du titulaire de la pièce, en précisant l'adresse à laquelle la réponse doit être envoyée.
    
    Aucune information personnelle de l'utilisateur du site kingetcie.com / kingetcie.fr n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de King et Cie et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation . et de modification des données vis à vis de l'utilisateur du site kingetcie.com / kingetcie.fr.
    
    Le site n'est pas déclaré à la CNIL car il ne recueille pas d'informations personnelles.
    
    Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.
    
    #### 8. Liens hypertextes et cookies.
    Le site kingetcie.com / kingetcie.fr peut-etre contient un certain nombre de liens hypertextes vers d'autres sites, mis en place avec l'autorisation de King et Cie. Cependant, King et Cie n'a pas la possibilité de vérifier le contenu des sites ainsi visités, et n'assumera en conséquence aucune responsabilité de ce fait.
    
    La navigation sur le site kingetcie.com / kingetcie.fr est susceptible de provoquer l'installation de un cookie sur l'ordinateur de l'utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l'identification de l'utilisateur, mais qui enregistre des informations relatives à la navigation d'un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.
    
    Cookies présents sur ce site :
    selectedLang (en LocalStorage): La langue selectée de l'utilisateur (Français ou Anglais).
    
    #### 9. Droit applicable et attribution de juridiction.
    Tout litige en relation avec l'utilisation du site kingetcie.com / kingetcie.fr est soumis aux tribunaux francais compétents statuant selon le droit français.
    
    #### 10. Les principales lois concernées.
    Loi № 78-87 du 6 janvier 1978, notamment modifiée par la loi № 2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux libertés.
    
    Loi № 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.
    
    #### 11. Lexique.
    Utilisateur : Internaute se connectant, utilisant le site susnommé.
    
    Informations personnelles : « les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi № 78-17 du 6 janvier 1978).`
  },
  "advertideas" : [
    {
    "image": "tailoring.jpg of an unbuttoned suit cuff, and a shirt",
    "text": "If you buy an off-the-peg suit the cuff buttons are merely decorative because one has to be able to adjust the sleeve length. With a bespoke suit, because it is made only to fit its wearer, and because a good tailor would never add anything that didn't function, those buttons can be undone. It is this attention to detail that King et Cie put into all our bespoke website builds. Contact us today to discuss your requirements."
    },
    {
      "image": "woodworking.jpg of some hand tools and wood shavings",
      "text": "Of course it's possible to buy decent quality functional flat-pack furniture, and millions of people have. But if you don't want to have the same furniture as millions of others, then you can get a carpenter to create something just for you, using all the skills and craftsmanship learned over decades of experience. It is this experience, skill and craftsmanship that King et Cie put into all our bespoke website builds. Contact us today to discuss your requirements."
    },
    {
      "image": "pizza.jpg of a lovely italian pizza",
      "text": "You can buy pizza in supermarkets but we all know its never as good as real Neopolitan pizza, made by an artisan, from high-protein flour, fresh tomato and buffalo mozzarella, and cooked rapidly in 1000&deg; wood-fired heat. It is this artisanship and obsession with doing it properly that King et Cie put into all our bespoke website builds. Contact us today to discuss your requirements."
    }
  ]
}