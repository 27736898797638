import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import ReactMarkdown from 'react-markdown'
import stripIndent from '../helpers/stripindent'
import SmartyPants from '../helpers/smartypants'
import {CONTENT} from '../../content'

import car from 'url:../../media/404cab.jpg'

class NotFound extends Component {
  render() {
    document.documentElement.classList.remove('has-lightbox')
    const {lang, pageChanged} = this.props
    const renderers = {
      link: props =>  props.href.match(/^(https?:)?\/\//)
        ? <a href={props.href} target="_blank">{props.children}</a> 
        : <Link onClick={pageChanged} to={`/${lang}${props.href}`}>{props.children}</Link>
    }
    const content = CONTENT[lang]
    const {titleBar, description, heading, article} = content.notfound
  
    return (<>
    <Helmet>
      <title>{ `${titleBar} - ${content.titleBar}` }</title>
      <meta name="description" content={description} />
      <meta name="robots" content="noindex" />
    </Helmet>
    <article className="notfound">
      <div className="row">
        <div className="column offset-1 span-10">
          <ReactMarkdown children={heading} />
        </div>
      </div>
      <div className="row">
        <section className="column offset-1 span-10">
          <ReactMarkdown
            renderers={renderers}
            children={SmartyPants(stripIndent(article))}
          />
          <img src={car} alt={ titleBar } />
        </section>
      </div>
    </article>
    </>)
  }
}

export default NotFound