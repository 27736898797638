export default en = {
  "languageName": "English",
  "titleBar": "King et Compagnie. Bespoke Website and Web app development",
  "description": "King et Cie is a web development agency formed in 2020 by Niall King, a front-end web developer with over 20 years commercial agency experience. Based in Charente, SW France, and working remotely, Niall is available for contract, freelance and personal web development, hosting and domain services.",
  "heading": "King et Compagnie",
  "subheading": "Creators of bespoke websites and applications",
  "footer":"2021 King et Compagnie. SIRET 89188075900016",
  "footerLinks":"[Legal](/legal) [LinkedIn](https://www.linkedin.com/company/king-et-cie/) [Facebook](https://www.facebook.com/kingetcie)",
  "menu": {
    "home": {
      "text": "Home",
      "path": "/"
    },
    "about": {
      "text": "About", 
      "path": "/about"
    },
    "services": {
      "text": "Services",
      "path": "/services"
    },
    "work": {
      "text": "Portfolio",
      "path": "/portfolio"
    },
    "contact": {
      "text": "Contact",
      "path": "/contact"
    }
  },
  "home":{
    "heading" : "### Welcome to King *&* Compagnie",
    "subheading": "#### Bespoke web development based in Charente, SW France.",
    "article":`**King et Cie** was set up by Niall King, a front-end web developer with over 20 years' experience in commercial web development with some of the top agencies and consultancies in London. We can offer your customers the kind of high-quality web experience that you would pay an agency tens or hundreds of thousands for, but at a price you can afford.

    For a detailed look at what we can do for you please take a look at the [Services section](/services).`,
    "footnote":`To see some more examples like these of the work we have done before please have a look at the [Portfolio section](/portfolio).`
  },
  "about":{
    "titleBar":"About King et Compagnie",
    "description":"King et Compagnie was formed in 2020 by Niall King, a front-end web developer with over 20 years commercial agency experience. Based in Charente, SW France, and working remotely, King et Cie is available for contract, freelance and personal web development, hosting and domain services.",
    "heading" : "### About King *&* Cie",
    "subheading": "#### King *&* Cie was set up by me, Niall King, in December 2020.",
    "article":`I have been a successful front-end web developer for over 20 years. In fact I made my first simple website in 1995 when Netscape 1 was but a boy and Internet Explorer was just a glint in Microsoft's eye. In 1999 I started doing it for a living, and have since had a \(mostly\) very enjoyable career, in some of the top agencies and consultancies in London, making fabulous websites for clients in all sorts of business sectors. I've made sites for retailers, restaurants, clubs, record labels, universities, HM Government, banks, charities, mobile phone networks, car manufacturers, major sporting events and Premiership football clubs. You can see details of a selection of these in the [Portfolio section](/portfolio).
    
    I believe that accessibility and SEO should be designed in from the start, that front-end development is a craft, just as much as design, that user experience is more important than developer experience, and that good design makes the world a better place.

    You may well be familiar with my work. If you've followed Manchester United between 2008 and 2014, or the Open golf championship between 2008 and 2012, then you've used my website. If you have booked a table through Livebookings or Bookatable the restaurant may well have been using my front-of-house software. If you've been considering a new Mazda recently, or comparing mobile packages on Three in the last few years, then you may well have used something I have built.

    But thanks to the effects of Brexit and Covid-19, in 2020 I decided that it was time leave London for rural France, and to start my own company. Covid showed that working fully remotely was possible, there was no need to work in offices or stay in central London, and I could enjoy much better weather whilst still getting good broadband speeds. Thus King et Cie was born. It's short for (and pronouced) 'King et Compagnie' and because it's registered in France I finally get to use my own surname as the company name.

    The great thing about front end development is that I get to make everything you see and touch on a website. Without a front end developer it's just a database, but add me and I get to turn that database into a beautiful and elegant user experience that can transform your business and your relationship with your customers. Now I can offer all of my experience, talent and general helpfulness to you, and because I don't have the London overheads I don't have to charge London prices.

    **What can I do for you?** The [Services section](/services) contains a fuller exploration of this, but in essence, if it's front-end \(i.e. the HTML/CSS/JS part\) and can be built then I can build it. I can possibly build it if it can't be built too, but that's rarer!`
  },
  "services":{
    "titleBar":"King et Compagnie Services",
    "description":"King et Compagnie provides front-end web development services, web app React development. Niall King is available for contract, freelance and personal web development, hosting and domain services ",
    "heading" : "### Our Services",
    "article":`#### What can King *&* Cie do for you?

    - Front-end web development
    - Internationalisation: Making your website multi-language. Did you know that by law all French-based businesses need to have a French-language website?
    - Conception and design help
    - Domain name registration and hosting services
    - Copywriting, updating design and content for better search engine optimisation \(SEO\), accessibility, or simply adding polish and professionalism
    - Migrating your site from a legacy platform to a new one
    - Updating your design and code to work on modern browsers, phones and tablets

    #### What advantages do we bring over ready-made templates or libraries?

        When you choose something off-the-peg for your website you're not engaging with someone who can take a look at your needs, your problems, and your opportunities and has the knowledge, expertise and experience to address them. All pre-made libraries \(e.g. Bootstrap, Foundation\) try to solve everyone's problems at once, and consequently never completely solve anyone's. Half the work is overriding their default behaviours, and the result is *way* too much code, and a slow website that looks just like all the others. To a lesser extent the same is true of ready-made templates for content management systems like Wordpress. You have much more control over what goes into each page, but at the end of the day it wasn't built for you - it's just a close approximation. To add to this, the underlying code for these websites goes stale. They all contain code to enable them to work in obsolete browsers \(e.g. Internet Explorer, Opera or Edge\) that nobody uses anymore, and that makes them just a little bit worse on modern ones than they could be.

        If you hire me to build you something bespoke, on the other hand, I bring extensive consultancy and agency experience to understand the problems you need to solve and the experience you want to deliver. I will code exactly what you need to do that, efficiently and with no extraneous bloat. I will deliver a fast, elegant, maintainable, accessible and search-optimised website that works across different browsers and devices, in different languages \(if required\) and uses the latest techniques to work on all the browsers that add value \(and omitting unnecessary code to pander to obsolete ones\). Your website will be the best it can possibly be. Imagine a website was a suit; if a Bootstrap site is Topman, one of mine is Huntsman.

        #### What is King *&* Cie's experience?

        In short, 20+ years of front-end web development, but this covers a wide area these days. I make websites, and web-apps, and I make them fast, elegant and efficient. I prefer what would be called UX development to pure JavaScript engineering, but I have oodles of experience doing that too. I code in HTML and CSS as well as JavaScript and consider those first two elements to be the foundation of a good website, not an inconvenience to be dealt with. I take a holistic view of what I am building and how it fits into the ecosystem it occupies, the market it sits in, or the company's overall strategy. I understand how business needs can be translated into and supported by the product. I see coding as a creative process, and work well with designers, clients, project managers and other developers to define and deliver a superlative user experience. I have experience of running teams of developers too, should that be required. And my early experience in sporting events means I deliver what I say I am going to, when I say it will be delivered. After all, you can't postpone the Open because the website isn't finished! If you would like to see my CV, [get in touch](/contact).

        #### What is meant by 'front-end'?

        Traditionally websites are split into front-end and back-end, the back-end being the behind the scenes stuff such as fetching data or processing form inputs, and the front-end being all the bits on a website that you see and touch. For years this was how all websites were made, and the front-end required mastery of HTML and CSS, with JavaScript providing elegant interactions with simple scripts where required. These days JavaScript has matured into a serious programming language, which can be used on the back-end as well as the front-end, and is used to create apps that may exist on the web, or natively on your computer or device. The lines between websites and webapps have blurred, as have the lines between back-end and front-end. For example this website is actually a web-app built with React, Parcel, and Markdown, so that I could more easily make it bilingual.

        #### What technologies do we use?

        The foundations are HTML, CSS and JavaScript to the latest standards. I use React, Redux, SASS/SCSS and JSON regularly, and I also have experience of GraphQL, LESS, Angular (but please don't ask me to again!), Webpack, Gulp and Parcel build scripts, FE testing with Jasmine and Jest, building for Electron, Building server-side JavaScript apps with NodeJS, and some fairly old experience maintaining server-side code written in Java & JSP, .Net \(C\# in Webforms or MVC / Razor varieties\)

        #### What about project managment?

        I have extensive experience of working in all sorts of Agile and Scrum teams, from Lean and Kanban to full-on 'cargo-cult Agile' as well as in a Waterfall environment, and I have come to understand two things. Firstly that it doesn't matter *how* you manage, it matters *that* you manage. Secondly the correct amount of management is 'as little as possible'. That's a little freebie for you there!

        #### Do we design as well as build?

        I wouldn't call myself a designer, and prefer to work with a professional designer, or from existing designs, but I have picked up a lot by osmosis in my career, particularly in the realm of user and human-centred design, and occasionally do design work as well as the development. One aspect of the design discipline I can do very well is taking something that exists and giving that 'polish' that makes it look professional and elegant. If this is the kind of work that you need, don't hesitate to [contact me](/contact).

        #### How much is this going to cost?

        It very much depends on your requirements. At the moment any work I do will be totally bespoke and would be charged for on a time and materials basis - either &euro;75 per hour or &euro;500 a day. You can pay me in Euros or Sterling. My sterling rates are £75 an hour and £500 a day. I hope to soon develop some fixed price offerings that give you a simple templated website that can be delivered quickly and demands little in maintenance, but gives your business the same polished, professional and bespoke look as something built to your exact requirements.

        VAT is charged at 20% for clients in the EU. For clients outside the EU (e.g. UK) it is VAT-free.

        #### How can we hire you from the UK? Is there IR35 in France?

        Hiring me from the UK is pretty much the same as hiring a contractor in the UK for an outside IR35 contract, but with fewer legal hoops to jump through. A client hires King & Cie under a works contract rather than an employment contract, I provide the service and invoice for work done. King et Cie is registered as an 'auto-entreprise' with the French authorities (URSSAF and INSEE) and I pay my own tax and social contributions in France, even on UK-earned income.
        
        We don't have IR35 in France - we have something similar but simpler.
        The aim is to ensure I am not a disguised employee, and to keep my UK client from being deemed to have opened a branch in France and becoming subject to French taxation. The requirement is that I must be independent and autonomous: i.e. there must be no superior-subordinate relationship between the parties.

        There are 6 conditions to meet:
        - I have control to work the way I want, free from supervision.
        - I establish my own schedule and hours.
        - I pay for my own supplies and assistance.
        - I am paid only for the work performed.
        - I can, and do, have other paying clients.
        - I market my services to the public.

        As long as the answer to these is 'yes', then I am independent and not an employee.

        Obviously the first and second requirements do not preclude meetings, comms, scoping and deadlines between me and a client.

        ##### Please [contact me](/contact) to discuss your requirements further.
    `
  },
  "work":{
    "titleBar":"Portfolio of previous work",
    "description":"With two decades of work under their belt it's no surprise that King et Compagnie have a great portfolio of prior work. Here are some of the projects that Niall worked on. ",
    "heading" : "### Portfolio",
    "subheading" : "#### King et Compagnie is built upon over two decades of successful commercial web development.",
    "article":`I've built a lot of sites over the years. Some are still around, some have succumbed to the passage of time. Here is a representative selection of my prior work. This should give you some idea of the standard of work I produce, and perhaps what I can do for you.
    `,
    "footnote": "#### If you like what you see please [contact me](/contact) to discuss your requirements further.",
    "list" : [
      {
        "date":"2022-2023",
        "client":"Japan Centre",
        "description":`Having impressed Japan Centre with my delivery for Yokocho, I was asked to help define a new technical architecture to set it up for the future, and to develop a new Japan Centre website on it. Working from the very start with a designer, a UX expert, SEO experts, internal stakeholders and an external back-end development agency, we specified the superb Amplience headless content management system, Algolia as a search engine, while BigCommerce was chosen as the shopping cart provider. For the front-end I built a React application, choosing small and fast integrations for speed and reliability. Over several months we defined, designed and then built the site, trained users in its maintenance, and provided on-going technical enhancements and support. Combining products, editorial, offers, events and recipes into one holistic experience
        
        Japancentre.com was the first time in almost 15 years where I was so thoroughly involved in every stage of its production, as well as being my first e-commerce site ever. It won Silver at the 2023 eCommerce Awards for Best UK eCommerce Site of the Year, and was described as "one of the quickest MACH Alliance technology builds". The new site led to a 4.2% increase in revenue, 10.7% increase in conversion and 7.5% increase in order value.
        
        [Japan Centre](https://japancentre.com/)`,
        "images" : [
          "jc1.jpg",
          "jc2.jpg",
          "jc4.jpg",
          "jc9.jpg",
          "jc8.jpg",
          "jc7.jpg"
        ]
      },
      {
        "date":"2021",
        "client":"Ramen Yokocho",
        "description":`I was contracted to develop a new website for Japan Centre's fantastic retro Japanese ramen restaurant, Yokocho. A previous agency had tried but failed to deliver the design, so I was called in to rescue the project, which I did. The site was built on a legacy Ruby on Rails back-end, which was a big learning curve for me, and did rather make things difficult technically. It was a good example of why hiring "full-stack" developers is often not the right approach, and a front-end specialist is needed.
        
        [Ramen Yokocho](https://ramenyokocho.com/)`,
        "images" : [
          "yokocho1.jpg",
          "yokocho2.jpg",
          "yokocho3.jpg",
          "yokocho4.jpg",
          "yokocho5.jpg",
          "yokocho6.jpg"
        ]
      },
      {
        "date":"2019",
        "client":"Paypal",
        "description":`I worked for Paypal in San Jose and the UK to update the legacy front end architecture underpinning the logged-out Paypal.com experience and translate it from DustJS to React. Although it was a big scope, covering every page you see when you're not logged in to your Paypal account, fundamentally this was a straightforward migration, often using existing pattern libraries. Nevertheless maintaining quality and performance was paramount, and I worked closely with the brand and design team in San Jose to ensure everything was consistent with their guidelines.
        
        [Paypal](https://www.paypal.com/)`,
        "images" : [
          "paypal1.jpg",
          "paypal2.jpg",
          "paypal4.jpg"
        ]
      },
      {
        "date":"2019",
        "client":"American Express",
        "description":`It's often very difficult for companies to evaluate the effectiveness of their digital marketing campaigns. Getting the data is one thing, but making sense of it becomes more difficult the larger your web presence is and the more data you have. To make it easier I produced this React web application for American Express. It presents a visualisation of their various SEO campaigns and general search engine performance in a simple way, allowing them to see quickly which campaigns are working and which are underperforming. It uses a Node/Express/MongoDB back-end.`,
        "images" : [
          "amex1.jpg",
          "amex2.jpg",
          "amex3.jpg",
          "amex4.jpg"
        ]
      },
      {
        "date":"2018-2019",
        "client":"Mazda",
        "description":`This was Mazda Europe's new website and platform, and rolled out to all their markets in Europe across 2018 and 2019. It was built using a React front-end with the EpiServer CMS on the back-end. It was a huge project, and I was part of a much larger development team for this one, so apart from contributing lots of bugfixes and work on polishing the styling I can only claim greater credit for parts of it, such as the dealer locator, or the new Mazda 3 launch microsite.
        
        [Mazda Europe](https://www.mazda.de/)`,
        "images" : [
          "mazda1.jpg",
          "mazda4.jpg"
        ]
      },
      {
        "date":"2019",
        "client":"Avis Inspires: Portugal",
        "description":`I built this microsite for Avis car rental in the UK, as part of their blog platform, which was based on Wordpress. I used a GraphQL plugin to retrieve the data in a headless fashion so as to have complete control of the front-end, which I built using React. I worked hand in hand with the designers on this to make it as beautiful as we could, even hand-crafting parallax animations and other scroll transitions.`,
        "images" : [
          "avis1.jpg",
          "avis2.jpg",
          "avis3.jpg"
        ]
      },
      {
        "date":"2019",
        "client":"Salomon Orchestra",
        "description":`This was my first pure design project in a while. The Salomon Orchestra are a London-based amateur symphony orchestra. The brief here was to create a modern, recognisable and distinct brand identity, to boost concert attendance, bookings, and awareness of the orchestra. I needed to find a visual vocabulary that was instantly identifiable as musical, but which avoided all the cliches of other classical music designs. Inspired by the Blue Note Records visual style (e.g. fonts, colour-tinted monochrome photography, and strong graphic design elements), I created the new logos, and these flyer and programme designs. Concert attendance was boosted considerably in 2019.`,
        "images" : [
          "salomon.jpg"
        ]
      },
      {
        "date":"2019",
        "client":"Dignity Funerals",
        "description":`A tool I created for Dignity Funerals to guide people through the steps that need to be taken when a loved one dies, in as simple and clear a way as possible in such a difficult time.
        
        Due to the client's hosting and infrastructure requirements this was built entirely by hand in 'vanilla' JavaScript, with Handlebars templates, rather than something dynamic like a React app, but it uses a modern microservices architecture to provide personally relevant information. It was very nice to once again craft something by hand like this, and very rewarding to build something that helps make a daunting task a little easier for people.
        
        [What to do when someone dies](https://www.dignityfunerals.co.uk/first-steps/)`,
        "images" : [
          "dignity1.jpg",
          "dignity3.jpg"
        ]
      },
      {
        "date":"2016",
        "client":"Lloyds Register of Shipping",
        "description":`I was part of the front-end team building the Marine Asset Survey Tool (MAST) for Lloyds Register. This was an Electron app built with Angular. It replaced a large number of disparate manual processes and paper forms with one app that surveyors could use in the field to survey and certify ships, oil rigs, and other sea-going craft. It's like an MoT for ships.`,
        "images" : [
          "lloyds.jpg"
        ]
      },
      {
        "date":"2013-2016",
        "client":"Three mobile UK",
        "description":`I was lead for the front-end development team on the Three UK website and developed much of their responsive website from 2013 to 2016, starting with the home page, then the product listing and detail pages, and the coverage checker. Due in part to our optimisation work Three was the only UK mobile network website to continue taking orders each iPhone launch day, while their rival sites crashed under the demand.`,
        "images" : [
          "three1.jpg",
          "three2.jpg",
          "three3.jpg",
          "three5.jpg"
        ]
      },
      {
        "date":"2011-2012",
        "client":"Livebookings (Bookatable)",
        "description":`I worked for Livebookings front end lead on a Front-of-House web application for restaurant management, allowing restauranteurs to design table plans, manage bookings, and seat walk-in customers in real time, on tablets or point-of-sale terminals. The app was built using a completely custom JavaScript front-end and a C# .Net back end.`,
        "images" : [
          "lb8.jpg",
          "lb4a.jpg",
          "lb5.jpg",
          "lb6.jpg",
        ]
      },
      {
        "date":"2007-2010",
        "client":"Manchester United",
        "description":`Manchester United is probably the biggest football club in the world, and their huge international following means they needed to offer their website in multiple languages. In 2007 I led the front end development for the introduction of Japanese, Chinese and Korean versions of ManUtd.com, and in 2010 I embarked upon a ground-up rewrite of the entire codebase to make it semantic, accessible, standards-compliant, to add Arabic, French and Spanish versions of the site.`,
        "images" : [
          "manutd1.jpg",
          "manutd3.jpg",
          "manutd4.jpg",
          "manutd6.jpg"
        ]
      },
      {
        "date":"2012",
        "client":"Cadbury",
        "description":`Spots v Stripes was Cadbury’s cross-media campaign to promote their sponsorship of the 2012 London Olympics. I developed the front end on several major elements of Cadbury's Spots v Stripes campaign website, notably their Catch and Race Season campaigns. I liked this one, as it was a very ambitious brief, an unusual design, and they sent us huge boxes of free chocolate!`,
        "images" : [
          "cadbury1.jpg",
          "cadbury2.jpg",
          "cadbury4.jpg"
        ]
      },
      {
        "date":"2008-2011",
        "client":"The Open Championship",
        "description":`In 2008 The Open Championship site was redesigned, and I wrote the entire front-end. I introduced then-revolutionary features to such a high-traffic website, such as a player carousel \(in the manner of Apple's Cover Flow\) and interactive course guides. From 2008 to 2011 I led subsequent development, including a new history section, video archive, and a completely new live scoring architecture, one of the most advanced to date.

        This site won the 2008 BIMA website production award, which is one of the most prestigious awards in the digital media industry. It was the only non-Flash website in the entire line-up to win an award that year.`,
        "images" : [
          "og1.jpg",
          "og2.jpg",
          "og4.jpg",
          "og6.jpg"
        ]
      },
      {
        "date":"2004",
        "client":"London Business School",
        "description":`I led front-end development of the 2004 London Business School website, and I had carte blanche to write the code as I wished. So I made a CSS-driven, completely standards compliant, accessible, adaptive site, with great SEO built in from the start. All this is standard practice now, but at the time it was a fairly novel, perhaps revolutionary, approach. This was the biggest site I had made at that point, and it really put my career on track.`,
        "images" : [
          "lbs1.jpg",
          "lbs2.jpg",
          "lbs5.jpg"
        ]
      },
      {
        "date":"2003",
        "client":"Stranger Than Paradise",
        "description":`Stranger Than Paradise was a monthly club night with a burlesque twist, and a strong romantic gypsy, circusy, carnival vibe. I'd been recommended to them following a site I made for Club Montepulciano, and I really worked well with the clients, who really gave me a lot of insight into the aesthetic they wanted for the club. This even included conversations about their favourite music and films, and of course I went to the club a few times to get a feel for it myself. This abundance of client involvement really enabled me to get a great feel for what the design needed to be. I designed and built the website for them and designed their logos and other bits of their brand identity, and they absolutely loved it.`,
        "images" : [
          "stp1.jpg",
          "stp3.jpg",
          "stp4.jpg"
        ]
      },
      {
        "date":"2002",
        "client":"Smallframes.com",
        "description":`Smallframes.com is a site I created back in 2002, when I had just bought a vintage Vespa ET3 and could not find any online resource that covered maintaining and repairing one. I therefore set about creating one for the community. Essentially I pulled together all the existing info I could, along with photo-guides created every time I did something to my scooter (which was often). Amazingly it became quite popular, and is used as a reference to this day. Like my Vespa, it is very old and needs a lot of work, but it's still alive.

        [Smallframes.com](http://smallframes.com/)`,
        "images" : [
          "sf1.jpg",
          "sf3.jpg",
          "sf4.jpg"
        ]
      }
    ]
  },
  "contact":{
    "titleBar":"Contact King et Compagnie",
    "description":"If you want to hire Niall to do any work for you here's how to get in touch.",
    "heading" : "### Contact King *&* Compagnie",
    "subheading" : "#### We'd love to talk.",
    "article" : `If you think King et Cie may be right for your project please fill in this form and we'll respond as soon as possible.`,
    "success" : `#### Thank you for getting in touch.

                  We will respond to your query as soon as we can.`,
    "spinnerText" : "Sending",
    "form" : {
      "subject" : {
        "label" : "How can we help you?",
        "error" : "Please select a subject",
        "options" : [
          {
            "label" : "Please select...",
            "value" : ""
          },
          {
            "label" : "I would like to discuss a contract role",
            "value" : "1 contract"
          },
          {
            "label" : "I would like to talk about a freelance project",
            "value" : "2 freelance"
          },
          {
            "label" : "I would like some help with domains and hosting",
            "value" : "3 domains"
          },
          {
            "label" : "I would like to make my website bilingual",
            "value" : "bilingual"
          },
          {
            "label" : "Other (please specify below)",
            "value" : "5 other"
          }
        ]
      },
      "firstname" : {
        "label" : "First Name",
        "error" : "Please enter your first name",
        "placeholder" : "John" 
      },
      "surname" : {
        "label" : "Surname",
        "error" : "Please enter your surname",
        "placeholder" : "Smith" 
      },
      "email" : {
        "label" : "Email address",
        "error" : "Please enter a valid email address",
        "placeholder" : "john.smith@example.com" 
      },
      "message" : {
        "label" : "Message",
        "error" : "Please enter your message",
        "placeholder" : "I wish to enquire about hiring you..." 
      },
      "submit" : "Send message"
    }  
  },
  "notfound": {
    "titleBar":"Page not found",
    "description":"King et Compagnie was formed in 2020 by Niall King, a front-end web developer with over 20 years commercial agency experience. Based in Charente, SW France, and working remotely, King et Cie is available for contract, freelance and personal web development, hosting and domain services ",
    "heading" : "### Page not found - 404",
    "article" : `## Sorry, this isn't what you were looking for. Unless what you were looking for was this lovely Peugeot 404 convertible, in which case, here you are!.`
  },
  "legal": {
    "titleBar":"Terms & Conditions",
    "description":"King et Compagnie was formed in 2020 by Niall King, a front-end web developer with over 20 years commercial agency experience. Based in Charente, SW France, and working remotely, King et Cie is available for contract, freelance and personal web development, hosting and domain services ",
    "heading" : "### Terms *&* Conditions",
    "article" : `#### 1. Presentation of the site.
    Under Article 6 of Law № 2004-575 of June 21, 2004 on confidence in the digital economy, users of the site kingetcie.com / kingetcie.fr are informed of the identity of the various stakeholders in the framework of its implementation and monitoring:
    
    Owner: King et Cie - 4 Grand' Rue - 16500 Confolens - France
    
    Creator: Niall King
    
    Publication manager: Niall King
    
    Webmaster: Niall King - king@kingetcie.com
    
    Host: 1&1 IONOS Ltd - Discovery House - 154 Southgate Street - Gloucester GL1 2EX - United Kingdom
    
    #### 2. General conditions of use of the site and the services offered.
    Use of the site kingetcie.com / kingetcie.fr implies full acceptance of the general conditions of use described below. These conditions of use may be modified or supplemented at any time, users of the kingetcie.com / kingetcie.fr site are therefore invited to consult them on a regular basis.
    
    This site is normally accessible to users at all times. An interruption due to technical maintenance may however be decided by kingetcie.com / kingetcie.fr, who will then endeavor to communicate to users beforehand the dates and times of the intervention.
    
    The site kingetcie.com / kingetcie.fr is updated regularly by King et Cie. Likewise, the legal notices can be modified at any time: they are nevertheless binding on the user, who is invited to refer to them as often as possible in order to become acquainted with them.
    
    #### 3. Description of the services provided.
    The purpose of the kingetcie.com / kingetcie.fr site is to provide information on all of the company's activities.
    
    King et Cie strives to provide the site kingetcie.com / kingetcie.fr with as accurate information as possible. However, it cannot be held responsible for omissions, inaccuracies and deficiencies in the update, whether due to it or to third party partners who provide it with this information.
    
    All the information indicated on the site kingetcie.com / kingetcie.fr are given as an indication, and are likely to evolve. Furthermore, the information on the site kingetcie.com / kingetcie.fr is not exhaustive. They are given subject to modifications having been made since they were put online.
    
    #### 4. Contractual limitations on technical data.
    The site uses JavaScript technology.
    
    The website cannot be held responsible for material damage related to the use of the site. In addition, the user of the site undertakes to access the site using recent equipment, which does not contain viruses and with an up-to-date latest generation browser.
    
    #### 5. Intellectual property and counterfeits.
    King et Cie is the owner of the intellectual property rights or holds the rights of use on all the elements accessible on the site, in particular the texts, images, graphics, logo, icons, sounds, software.
    
    Any reproduction, representation, modification, publication, adaptation of all or part of the elements of the site, whatever the means or process used, is prohibited without the prior written consent of: King et Cie.
    
    Any unauthorized use of the site or any of the elements it contains will be considered as constituting an infringement and prosecuted in accordance with the provisions of articles L.335-2 and following of the Intellectual Property Code.
    
    #### 6. Limitations of Liability.
    King et Cie cannot be held responsible for direct or indirect damage caused to the user's equipment when accessing the site kingetcie.com / kingetcie.fr, and resulting either from the use of equipment that does not meet the specifications indicated in point 4, either the appearance of a bug or an incompatibility
    
    King et Cie cannot also be held responsible for indirect damages (such as for example a loss of market or loss of a chance) consecutive to the use of the site kingetcie.com / kingetcie.fr.
    
    Interactive spaces (possibility to ask questions in the contact space) are available to users. King et Cie reserves the right to remove, without prior notice, any content posted in this space that would violate the law applicable in France, in particular the provisions relating to data protection. Where applicable, King et Cie also reserves the right to question the civil and / or criminal liability of the user, in particular in the event of a racist, abusive, defamatory or pornographic message, whatever the medium used ( text, photography…).
    
    #### 7. Management of personal data.
    In France, personal data is protected in particular by law № 78-87 of January 6, 1978, law № 2004-801 of August 6, 2004, article L. 226-13 of the Penal Code and the European Directive of October 24, 1995.
    
    When using the kingetcie.com / kingetcie.fr site, the following may be collected: the URL of the links through which the user accessed the kingetcie.com / kingetcie.fr site, the supplier of user access, the user's Internet Protocol (IP) address.
    
    In any event King et Cie only collects personal information about the user for the need of certain services offered by the site kingetcie.com / kingetcie.fr. The user provides this information with full knowledge of the facts, in particular when he enters it himself. It is then specified to the user of the site kingetcie.com / kingetcie.fr the obligation or not to provide this information.
    
    In accordance with the provisions of articles 38 and following of the law 78-17 of January 6, 1978 relating to data processing, files and freedoms, any user has a right of access, rectification and opposition to personal data. concerning him, by making his written and signed request, accompanied by a copy of the identity document with signature of the holder of the document, specifying the address to which the reply must be sent.
    
    No personal information of the user of the site kingetcie.com / kingetcie.fr is published without the knowledge of the user, exchanged, transferred, assigned or sold on any medium whatsoever to third parties. Only the assumption of the repurchase of King et Cie and his rights would allow the transmission of said information to the possible purchaser who would in turn be bound by the same obligation of conservation. and modification of data with regard to the user of the site kingetcie.com / kingetcie.fr.
    
    The site is not declared to the CNIL because it does not collect personal information.
    
    The databases are protected by the provisions of the law of July 1, 1998 transposing Directive 96/9 of March 11, 1996 on the legal protection of databases.
    
    #### 8. Hypertext links and cookies.
    The kingetcie.com / kingetcie.fr site may contain hypertext links to other sites, set up with the authorization of King et Cie. However, King et Cie does not have the possibility to check the content of the sites thus visited, and will therefore not assume any responsibility for this fact.
    
    Browsing the kingetcie.com / kingetcie.fr site may cause the installation of a cookie on the user's computer. A cookie is a small file, which does not allow the identification of the user, but which records information relating to the navigation of a computer on a site. The data thus obtained is intended to facilitate subsequent navigation on the site.
    
    Cookies on this site:
    selectedLang (in LocalStorage): The user's selected language (English or French)
    
    #### 9. Applicable law and attribution of jurisdiction.
    Any dispute in connection with the use of the site kingetcie.com / kingetcie.fr is submitted to the competent French courts ruling under French law.
    
    #### 10. The main laws concerned.
    Law № 78-87 of January 6, 1978, notably modified by law № 2004-801 of August 6, 2004 relating to data processing, files and freedoms.
    
    Law № 2004-575 of June 21, 2004 for confidence in the digital economy.
    
    #### 11. Lexicon.
    User: Internet user connecting, using the aforementioned site.
    
    Personal information: "information which allows, in any form whatsoever, directly or indirectly, the identification of the natural persons to whom it applies" (article 4 of law № 78-17 of January 6, 1978).`
  },
  "advertideas" : [
    {
    "image": "tailoring.jpg of an unbuttoned suit cuff, and a shirt",
    "text": "If you buy an off-the-peg suit the cuff buttons are merely decorative because one has to be able to adjust the sleeve length. With a bespoke suit, because it is made only to fit its wearer, and because a good tailor would never add anything that didn't function, those buttons can be undone. It is this attention to detail that King et Cie put into all our bespoke website builds. Contact us today to discuss your requirements."
    },
    {
      "image": "woodworking.jpg of some hand tools and wood shavings",
      "text": "Of course it's possible to buy decent quality functional flat-pack furniture, and millions of people have. But if you don't want to have the same furniture as millions of others, then you can get a carpenter to create something just for you, using all the skills and craftsmanship learned over decades of experience. It is this experience, skill and craftsmanship that King et Cie put into all our bespoke website builds. Contact us today to discuss your requirements."
    },
    {
      "image": "pizza.jpg of a lovely italian pizza",
      "text": "You can buy pizza in supermarkets but we all know its never as good as real Neopolitan pizza, made by an artisan, from high-protein flour, fresh tomato and buffalo mozzarella, and cooked rapidly in 1000&deg; wood-fired heat. It is this artisanship and obsession with doing it properly that King et Cie put into all our bespoke website builds. Contact us today to discuss your requirements."
    }
  ]
}