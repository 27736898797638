import React, { Component, useEffect } from "react"
import {Helmet} from "react-helmet"

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom"

import Header from './components/header'
import Footer from './components/footer'

import Home from './components/home'
import About from './components/about'
import Services from './components/services'
import Work from './components/work'
import Contact from './components/contact'
import NotFound from './components/notfound'
import Legal from './components/legal'

import {CONTENT, SUPPORTED_LANGUAGES } from '../content'

function useScrollMemory() {
  const history = useHistory()
  useEffect(() => {
    if (history.action !== "POP") {
      window.scrollTo(0, 0)
    }
  }, [history.location.pathname])
}

function ScrollMemory() {
  useScrollMemory()
  return null
}


const m = window.matchMedia
const d = document.documentElement.classList
const a = '(min-width:'
const b = 'em)'
const q = [m(a + 0 + b), m(a + 40 + b), m(a + 60 + b), m(a + 90 + b)]

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedLang: '', 
      currentPage:'',
      breakpoint: 'desktop'
    }
  }

  componentDidMount() {
    for (let i = 0; i < 4; i++) {
      q[i].addListener(this.changeBreakpoint) // new syntax not yet well enough supported
    }
    this.changeBreakpoint()
  }

  componentWillUnmount() {
    for (let i = 0; i < 4; i++) {
      q[i].removeListener(this.changeBreakpoint) // new syntax not yet well enough supported
    }
  }

  changeBreakpoint = () => {
    let p = 'desktop'
    d.remove('mobile', 'tablet', p, 'widescreen')
    if (q[3].matches) {p = 'widescreen'}
    else if (q[2].matches) {}
    else if (q[1].matches) p = 'tablet'
    else if (q[0].matches) p = 'mobile'
    d.add(p)
    this.setState({breakpoint: p})
  }

  routes = () => {
    const links = []
    const components = {
      home: Home,
      about: About,
      services: Services,
      work: Work,
      contact: Contact,
      notfound: NotFound
    }

    SUPPORTED_LANGUAGES.forEach((lang) => {
      Object.entries(CONTENT[lang].menu).forEach(([key, value]) => {
        const CustomTag = components[key]
        links.push(<Route exact key={`${key}-${lang}`} path={`/${lang}${value.path}`}><CustomTag lang={lang} pageChanged={this.pageChanged} languageChanged={this.languageChanged} bp={this.state.breakpoint} /></Route>)
        links.push(<Redirect exact key={`${key}-${lang}-r`} from={`${value.path}`} to={`/${lang}${value.path}`} lang={lang} render={this.state.selectedLang.length}/>)
      })
      links.push(<Route exact key={`legal-${lang}`} path={`/${lang}/legal`}><Legal lang={lang} pageChanged={this.pageChanged} languageChanged={this.languageChanged} bp={this.state.breakpoint} /></Route>)
      links.push(<Redirect exact key={`legal-${lang}-r`} from={`/legal`} to={`/${lang}/legal`} lang={lang} render={this.state.selectedLang.length}/>)
    })
    return links
  }

  languageChanged = (ev, lang) => {
    this.setState({selectedLang: lang})
    localStorage.setItem('selectedLang', lang);
  }

  pageChanged = (page) => {
    this.setState({currentPage: page})
  }

  render() {

    let {breakpoint, selectedLang} = this.state
    const loc = window.location
    const pathname = loc.pathname

    if (!selectedLang) {
      // language order logic is domain < pathname < localstorage
      // if the domain is .com default to English, otherwise default to French
      const host = loc.hostname.split('.')
      const tld = host[host.length-1]
      if (tld === 'fr'){
        selectedLang = 'fr'
      } else {
        selectedLang = 'en'
      }

      // if the url path gives us a lang, change to that, overriding domain.
      const langparam = pathname.split('/')[1]
      if (SUPPORTED_LANGUAGES.includes(langparam)){
        selectedLang = langparam
      }

      // finally if we have localstorage that overrides url
      var ls = localStorage.getItem('selectedLang')
      if (ls && SUPPORTED_LANGUAGES.includes(ls)) {
        selectedLang = ls
      }
    }

    localStorage.setItem('selectedLang', selectedLang);
    const content = CONTENT[selectedLang]
    //return <p>fuck off</p>
    return(
      <BrowserRouter>
        <ScrollMemory/>
        <Helmet>
          <html lang={selectedLang}/>
          <meta charSet="utf-8" />
          <title>{ content.titleBar }</title>
          <meta name="description" content={content.description} />
          <meta http-equiv="content-language" content={selectedLang}/>
        </Helmet>
      
        <Header lang={selectedLang} langChanged={this.languageChanged} pageChanged={this.pageChanged} currentPage={pathname} bp={breakpoint} />
        <Switch>
          {this.routes()}
          <Route path="*">
            <NotFound lang={selectedLang} bp={breakpoint} />
          </Route>
        </Switch>
        <Footer lang={selectedLang} langChanged={this.languageChanged} pageChanged={this.pageChanged} currentPage={pathname} bp={breakpoint} />
      </BrowserRouter>
    )
  }

}

export default App
