import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from "react-router-dom"
import ReactMarkdown from 'react-markdown'
import stripIndent from '../helpers/stripindent'
import SmartyPants from '../helpers/smartypants'
import {CONTENT} from '../../content'


import avis1 from 'url:../../media/portfolio/small/avis1.jpg'
import og2 from 'url:../../media/portfolio/small/og2.jpg'
import three1 from 'url:../../media/portfolio/small/three1.jpg'
import cadbury2 from 'url:../../media/portfolio/small/cadbury2.jpg'
import jc1 from 'url:../../media/portfolio/small/jc1.jpg'
import yokocho1 from 'url:../../media/portfolio/small/yokocho1.jpg'

const imageCollection = {
  cadbury2,
  three1,  
  og2,  
  avis1,
  jc1,
  yokocho1
}

class Home extends Component {

  imageStack = () => {
    const {bp} = this.props
    const arr = Object.entries(imageCollection).map(([key, url]) => {
      return <div key={key} className={`picture`} style={{backgroundImage : `url(${url}`}}>
        <img src={url} alt='' />
      </div>
    })
    return <div className="row">
    <section className={`preview column ${bp==='mobile' ? 'offset-3 span-5' : bp==='tablet' ? 'offset-4 span-4' : 'offset-4 span-4'}`}>
      <div className={`stack opened`}>{arr}</div>
    </section>
  </div>
  }

  render() {
    document.documentElement.classList.remove('has-lightbox')
    const {lang, pageChanged, bp} = this.props
    const content=CONTENT[lang]
    const {article, heading, subheading, footnote} = content.home
    const renderers = {
      link: props =>  props.href.match(/^(https?:)?\/\//)
        ? <a href={props.href} target="_blank">{props.children}</a> 
        : <Link onClick={pageChanged} to={`/${lang}${props.href}`}>{props.children}</Link>
    }

    return (<>
    <Helmet>
      <title>{ content.titleBar }</title>
      <meta name="description" content={content.description} />
      <meta name="canonical" content={`https://kingetcie.com/${lang}/`} />
    </Helmet>
    <article>
      <div className="row">
        <div className={`column ${bp==='mobile' ? 'span-12' :  'offset-1 span-10'}`}>
          <ReactMarkdown children={heading} />
        </div>
      </div>
      <div className="row">
        <div className={`column ${bp==='mobile' ? 'span-12' :  'offset-1 span-10'}`}>
          <ReactMarkdown children={subheading} />
        </div>
      </div>
      <div className="row">
        <section  className={`column ${bp==='mobile' ? 'span-12' :  'offset-1 span-10'}`}>
        <ReactMarkdown
          renderers={renderers}
          children={SmartyPants(stripIndent(article))}
        />
        </section>
      </div>
      <this.imageStack/>
      <div className="row">
        <section  className={`column ${bp==='mobile' ? 'span-12' :  'offset-1 span-10'}`}>
        <ReactMarkdown
          renderers={renderers}
          children={SmartyPants(stripIndent(footnote))}
        />
        </section>
      </div>
    </article>
    </>)
  }
}

export default Home