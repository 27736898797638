import React, { Component } from 'react';
import { Link } from "react-router-dom";

import {CONTENT, SUPPORTED_LANGUAGES} from '../../content'
class LanguageSwitcher extends Component {

  renderMenu = (currentLang) => {
    let pageParam = this.props.currentPage.split('/')[2]
    if (typeof pageParam === 'undefined') pageParam = this.props.currentPage.split('/')[1]
    const links = []
    let menuKey = ''
  
    Object.entries(CONTENT[currentLang].menu).forEach(([key, value]) => {
      if (value.path == `/${pageParam}`) {
        menuKey = key
      }
    })

    SUPPORTED_LANGUAGES.forEach((lang) => {
      const langName = CONTENT[lang].languageName
      const value = CONTENT[lang].menu[menuKey] || {path:'/'}
      if (lang === currentLang) {
        links.push(<li key={lang}>{langName}</li>)
      } else {
        if (pageParam === 'legal') {
          links.push(<li key={lang}><Link to={`/${lang}/legal`} onClick={(ev)=>this.props.langChanged(ev,lang)}>{langName}</Link></li>)
        } else {
          links.push(<li key={lang}><Link to={`/${lang}${value.path}`} onClick={(ev)=>this.props.langChanged(ev,lang)}>{langName}</Link></li>)
        }
      }
    })
    return links
  }

  render() {
    return (
    <ul className="languageswitcher">
      {this.renderMenu(this.props.lang)}
    </ul>
    )
  }
}

export default LanguageSwitcher