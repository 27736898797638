import React, { Component, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import ReactMarkdown from 'react-markdown'
import stripIndent from '../helpers/stripindent'
import SmartyPants from '../helpers/smartypants'
import { Link } from "react-router-dom"
import {CONTENT} from '../../content'


/* importing each image separately is bloody silly. Shouldn't have to do it like this */
/* keep an eye on https://github.com/parcel-bundler/parcel/pull/5933 and https://github.com/parcel-bundler/parcel/issues/4683 */
import salomon_small from 'url:../../media/portfolio/small/salomon.jpg'
import dignity1_small from 'url:../../media/portfolio/small/dignity1.jpg'
import dignity3_small from 'url:../../media/portfolio/small/dignity3.jpg'
import paypal1_small from 'url:../../media/portfolio/small/paypal1.jpg'
import paypal2_small from 'url:../../media/portfolio/small/paypal2.jpg'
import paypal4_small from 'url:../../media/portfolio/small/paypal4.jpg'
import amex1_small from 'url:../../media/portfolio/small/amex1.jpg'
import amex2_small from 'url:../../media/portfolio/small/amex2.jpg'
import amex3_small from 'url:../../media/portfolio/small/amex3.jpg'
import amex4_small from 'url:../../media/portfolio/small/amex4.jpg'
import avis1_small from 'url:../../media/portfolio/small/avis1.jpg'
import avis2_small from 'url:../../media/portfolio/small/avis2.jpg'
import avis3_small from 'url:../../media/portfolio/small/avis3.jpg'
import mazda1_small from 'url:../../media/portfolio/small/mazda1.jpg'
import mazda4_small from 'url:../../media/portfolio/small/mazda4.jpg'
import lloyds_small from 'url:../../media/portfolio/small/lloyds.jpg'
import three1_small from 'url:../../media/portfolio/small/three1.jpg'
import three2_small from 'url:../../media/portfolio/small/three2.jpg'
import three3_small from 'url:../../media/portfolio/small/three3.jpg'
import three5_small from 'url:../../media/portfolio/small/three5.jpg'
import lb8_small from 'url:../../media/portfolio/small/lb8.jpg'
import lb4_small from 'url:../../media/portfolio/small/lb4.jpg'
import lb5_small from 'url:../../media/portfolio/small/lb5.jpg'
import lb6_small from 'url:../../media/portfolio/small/lb6.jpg'
import manutd1_small from 'url:../../media/portfolio/small/manutd1.jpg'
import manutd3_small from 'url:../../media/portfolio/small/manutd3.jpg'
import manutd4_small from 'url:../../media/portfolio/small/manutd4.jpg'
import manutd6_small from 'url:../../media/portfolio/small/manutd6.jpg'
import cadbury1_small from 'url:../../media/portfolio/small/cadbury1.jpg'
import cadbury2_small from 'url:../../media/portfolio/small/cadbury2.jpg'
import cadbury4_small from 'url:../../media/portfolio/small/cadbury4.jpg'
import og1_small from 'url:../../media/portfolio/small/og1.jpg'
import og2_small from 'url:../../media/portfolio/small/og2.jpg'
import og4_small from 'url:../../media/portfolio/small/og4.jpg'
import og6_small from 'url:../../media/portfolio/small/og6.jpg'
import lbs1_small from 'url:../../media/portfolio/small/lbs1.jpg'
import lbs2_small from 'url:../../media/portfolio/small/lbs2.jpg'
import lbs5_small from 'url:../../media/portfolio/small/lbs5.jpg'
import stp1_small from 'url:../../media/portfolio/small/stp1.jpg'
import stp3_small from 'url:../../media/portfolio/small/stp3.jpg'
import stp4_small from 'url:../../media/portfolio/small/stp4.jpg'
import sf1_small from 'url:../../media/portfolio/small/sf1.jpg'
import sf3_small from 'url:../../media/portfolio/small/sf3.jpg'
import sf4_small from 'url:../../media/portfolio/small/sf4.jpg'
import jc1_small from 'url:../../media/portfolio/small/jc1.jpg'
import jc2_small from 'url:../../media/portfolio/small/jc2.jpg'
import jc4_small from 'url:../../media/portfolio/small/jc4.jpg'
import jc7_small from 'url:../../media/portfolio/small/jc7.jpg'
import jc8_small from 'url:../../media/portfolio/small/jc8.jpg'
import jc9_small from 'url:../../media/portfolio/small/jc9.jpg'
import yokocho1_small from 'url:../../media/portfolio/small/yokocho1.jpg'
import yokocho2_small from 'url:../../media/portfolio/small/yokocho2.jpg'
import yokocho3_small from 'url:../../media/portfolio/small/yokocho3.jpg'
import yokocho4_small from 'url:../../media/portfolio/small/yokocho4.jpg'
import yokocho5_small from 'url:../../media/portfolio/small/yokocho5.jpg'
import yokocho6_small from 'url:../../media/portfolio/small/yokocho6.jpg'

import salomon from 'url:../../media/portfolio/salomon.jpg'
import dignity1 from 'url:../../media/portfolio/dignity1.jpg'
import dignity3 from 'url:../../media/portfolio/dignity3.jpg'
import paypal1 from 'url:../../media/portfolio/paypal1.jpg'
import paypal2 from 'url:../../media/portfolio/paypal2.jpg'
import paypal4 from 'url:../../media/portfolio/paypal4.jpg'
import amex1 from 'url:../../media/portfolio/amex1.jpg'
import amex2 from 'url:../../media/portfolio/amex2.jpg'
import amex3 from 'url:../../media/portfolio/amex3.jpg'
import amex4 from 'url:../../media/portfolio/amex4.jpg'
import avis1 from 'url:../../media/portfolio/avis1.jpg'
import avis2 from 'url:../../media/portfolio/avis2.jpg'
import avis3 from 'url:../../media/portfolio/avis3.jpg'
import mazda1 from 'url:../../media/portfolio/mazda1.jpg'
import mazda4 from 'url:../../media/portfolio/mazda4.jpg'
import lloyds from 'url:../../media/portfolio/lloyds.jpg'
import three1 from 'url:../../media/portfolio/three1.jpg'
import three2 from 'url:../../media/portfolio/three2.jpg'
import three3 from 'url:../../media/portfolio/three3.jpg'
import three5 from 'url:../../media/portfolio/three5.jpg'
import lb8 from 'url:../../media/portfolio/lb8.jpg'
import lb4 from 'url:../../media/portfolio/lb4.jpg'
import lb5 from 'url:../../media/portfolio/lb5.jpg'
import lb6 from 'url:../../media/portfolio/lb6.jpg'
import manutd1 from 'url:../../media/portfolio/manutd1.jpg'
import manutd3 from 'url:../../media/portfolio/manutd3.jpg'
import manutd4 from 'url:../../media/portfolio/manutd4.jpg'
import manutd6 from 'url:../../media/portfolio/manutd6.jpg'
import cadbury1 from 'url:../../media/portfolio/cadbury1.jpg'
import cadbury2 from 'url:../../media/portfolio/cadbury2.jpg'
import cadbury4 from 'url:../../media/portfolio/cadbury4.jpg'
import og1 from 'url:../../media/portfolio/og1.jpg'
import og2 from 'url:../../media/portfolio/og2.jpg'
import og4 from 'url:../../media/portfolio/og4.jpg'
import og6 from 'url:../../media/portfolio/og6.jpg'
import lbs1 from 'url:../../media/portfolio/lbs1.jpg'
import lbs2 from 'url:../../media/portfolio/lbs2.jpg'
import lbs5 from 'url:../../media/portfolio/lbs5.jpg'
import stp1 from 'url:../../media/portfolio/stp1.jpg'
import stp3 from 'url:../../media/portfolio/stp3.jpg'
import stp4 from 'url:../../media/portfolio/stp4.jpg'
import sf1 from 'url:../../media/portfolio/sf1.jpg'
import sf3 from 'url:../../media/portfolio/sf3.jpg'
import sf4 from 'url:../../media/portfolio/sf4.jpg'
import jc1 from 'url:../../media/portfolio/jc1.jpg'
import jc2 from 'url:../../media/portfolio/jc2.jpg'
import jc4 from 'url:../../media/portfolio/jc4.jpg'
import jc7 from 'url:../../media/portfolio/jc7.jpg'
import jc8 from 'url:../../media/portfolio/jc8.jpg'
import jc9 from 'url:../../media/portfolio/jc9.jpg'
import yokocho1 from 'url:../../media/portfolio/yokocho1.jpg'
import yokocho2 from 'url:../../media/portfolio/yokocho2.jpg'
import yokocho3 from 'url:../../media/portfolio/yokocho3.jpg'
import yokocho4 from 'url:../../media/portfolio/yokocho4.jpg'
import yokocho5 from 'url:../../media/portfolio/yokocho5.jpg'
import yokocho6 from 'url:../../media/portfolio/yokocho6.jpg'

const imageCollection = {
  salomon,
  dignity1,  
  dignity3,  
  paypal1,  
  paypal2,  
  paypal4,  
  amex1,  
  amex2,  
  amex3,  
  amex4,  
  avis1,  
  avis2,  
  avis3,  
  mazda1,  
  mazda4,  
  lloyds,  
  three1,  
  three2,  
  three3,  
  three5,  
  lb8,  
  lb4,  
  lb5,  
  lb6,  
  manutd1,  
  manutd3,  
  manutd4,  
  manutd6,  
  cadbury1,  
  cadbury2,  
  cadbury4,  
  og1,  
  og2,  
  og4,  
  og6,  
  lbs1,  
  lbs2,  
  lbs5,  
  stp1,  
  stp3,  
  stp4,  
  sf1,  
  sf3,
  sf4,
  jc1,
  jc2,
  jc4,
  jc7,
  jc8,
  jc9,
  yokocho1,
  yokocho2,
  yokocho3,
  yokocho4,
  yokocho5,
  yokocho6,
  salomon_small,
  dignity1_small,  
  dignity3_small,  
  paypal1_small,  
  paypal2_small,  
  paypal4_small,  
  amex1_small,  
  amex2_small,  
  amex3_small,  
  amex4_small,  
  avis1_small,  
  avis2_small,  
  avis3_small,  
  mazda1_small,  
  mazda4_small,  
  lloyds_small,  
  three1_small,  
  three2_small,  
  three3_small,  
  three5_small,  
  lb8_small,  
  lb4_small,  
  lb5_small,  
  lb6_small,  
  manutd1_small,  
  manutd3_small,  
  manutd4_small,  
  manutd6_small,  
  cadbury1_small,  
  cadbury2_small,  
  cadbury4_small,  
  og1_small,  
  og2_small,  
  og4_small,  
  og6_small,  
  lbs1_small,  
  lbs2_small,  
  lbs5_small,  
  stp1_small,  
  stp3_small,  
  stp4_small,  
  sf1_small,  
  sf3_small,
  sf4_small,
  jc1_small,
  jc2_small,
  jc4_small,
  jc7_small,
  jc8_small,
  jc9_small,
  yokocho1_small,
  yokocho2_small,
  yokocho3_small,
  yokocho4_small,
  yokocho5_small,
  yokocho6_small
}

const Work = (props) => {

  /* 
  // safari is shit so this doesn't work
  const is = 'IntersectionObserver' in window
 
  useEffect(() => {
    // lazy load bg images
    const pictures = [].slice.call(document.querySelectorAll('.picture'))
    if (is) {
      //deliberate var to hoist pictureObserver
      var pictureObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            entry.target.classList.remove('invisible')
            pictureObserver.unobserve(entry.target)
          }
        });
      });
      pictures.forEach(function(picture) {
        pictureObserver.observe(picture)
      });
    }
    return () => {
      if (is) {
        pictures.forEach(function(picture) {
          pictureObserver.unobserve(picture)
        });
      }
    }
  }, []); */

  const [lightbox, setLightbox] = useState('')

  const toggleLightbox = (ev) => {
    const key = ev.target.getAttribute('data-key')
    ev.stopPropagation()

    if (key && !lightbox) {
      setLightbox(key)
      document.documentElement.classList.add('has-lightbox')
    } else {
      setLightbox('')
      document.documentElement.classList.remove('has-lightbox')
    }
  }

  const imageStack = (images, alt) => {
    let lb = false
    const arr = [...images].reverse().map(image => {
      const full = image.substring(image.lastIndexOf('/')).replace('.jpg','')
      const small = full + '_small';
      const sl = full === lightbox
      if (sl) {
        lb = true
      }

      const url = imageCollection[full]
      const thumb = imageCollection[small]
      return <div key={full} data-key={full} className={`picture${sl ? ' lightbox' : ''}`} style={{backgroundImage : `url(${thumb}`}} onMouseUp={toggleLightbox}>
        <img loading="lazy" src={sl ? url : thumb} alt={alt} data-key={full} />
      </div>
    })
  
    return <div className={`stack with-${images.length}${lb ? ' lightbox' : ''}`}>{arr}</div>
  }
  
  const {lang, pageChanged, bp} = props
  const content = CONTENT[lang]
  const {titleBar, description, heading, subheading, article, list, footnote} = content.work

  const renderers = {
    link: props =>  props.href.match(/^(https?:)?\/\//)
      ? <a href={props.href} target="_blank" rel="noopener">{props.children}</a> 
      : <Link onClick={pageChanged} to={`/${lang}${props.href}`}>{props.children}</Link>
  }


  return (<>
    <Helmet>
      <title>{ `${titleBar} - ${content.titleBar}` }</title>
      <meta name="description" content={description} />
      <meta name="canonical" content={`https://kingetcie.com/${lang}/portfolio`} />
    </Helmet>
    <article className="portfolio">
    <div className="row">
      <div className={`column ${bp==='mobile' ? 'span-12' :  'offset-1 span-10'}`}>
        <ReactMarkdown children={heading} />
      </div>
    </div>
    <div className="row">
      <section  className={`column ${bp==='mobile' ? 'span-12' :  'offset-1 span-10'}`}>
        <ReactMarkdown children={subheading} />
        <ReactMarkdown children={SmartyPants(stripIndent(article))} />
      </section>
      {list.map(item => (
      <ul key={item.client}>
        <li>
          <div className="row">
            <h4 className={`column ${bp==='mobile' ? 'span-9' :  'offset-1 span-8'}`}>{item.client}</h4>
            <h5 className={`column date ${bp==='mobile' ? 'span-3' :  'span-2'}`}>{item.date}</h5>
          </div>
          <div className="row">
            <div className={`column ${bp==='mobile' ? 'span-4' :  'offset-1 span-4'}`}>
              {imageStack(item.images, item.client)}
            </div>
            <div className={`column ${bp==='mobile' ? 'span-8' :  'span-6'}`}>       
              <ReactMarkdown
                renderers={renderers}
                children={SmartyPants(stripIndent(item.description))}
              />
            </div>
          </div>
        </li>
      </ul>
      ))}
      <section className="column offset-1 span-10">
        <ReactMarkdown
          renderers={renderers}
          children={SmartyPants(stripIndent(footnote))}
        />
      </section>
    </div>
  </article>
  </>)
}
  
  
export default Work